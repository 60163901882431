import React from 'react';
import ColoredHeading from 'components/ColoredHeading/ColoredHeading';
import { Grid, Container, Typography } from '@material-ui/core';
import ColoredButton from 'components/ColoredButton/ColoredButton';
import ImageComposition from 'components/ImageComposition/ImageComposition';
import useStyles from './styles';

const IntroSection = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <ColoredHeading>Santa Running Team</ColoredHeading>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" className={classes.headingTertiary}>
              Ιδρυση – Μελη
            </Typography>

            <Typography variant="body1" className={classes.paragraph}>
              Η Santa Running Team είναι μια ερασιτεχνική ομάδα δρομέων στην Αγία Παρασκευή. Ιδρύθηκε 23 Δεκεμβρίου του 2017 και ο
              πρώτος αγώνας που συμμετείχαν δρομείς της ομάδας ήταν το SNF RUN: 2018 First Run στην αλλαγή του χρόνου το 2018 στο
              ίδρυμα Σταύρος Νιάρχος.
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              Σήμερα αριθμεί περισσότερους από 250 δρομείς και το 2018 συμμετείχε σε 62 δρομικά event σε όλη την Ελλάδα, ενώ το
              2019 συμμετείχε σε 72 δρομικά event σε όλη την Ελλάδα και το εξωτερικό.
            </Typography>
            <Typography variant="h3" className={classes.headingTertiary}>
              Το Σωματειο
            </Typography>

            <Typography variant="body1" className={classes.paragraph}>
              Την Τετάρτη 15 Μαΐου 2019 ιδρύθηκε στην Αγία Παρασκευή το Αθλητικό Σωματείο Αγίας Παρασκευής – Santa Athletic Club –
              Α.Σ.Α.Π. και η Santa Running Team πλέον αποτελεί το τμήμα δρομέων υγείας του Σωματείου.
            </Typography>

            <ColoredButton href="https://www.facebook.com/SantaRunningTeam/" target="_blank" rel="noopener noreferrer">
              Facebook Link &rarr;
            </ColoredButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <ImageComposition />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default IntroSection;
