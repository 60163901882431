const eventTypes = {
  FETCH_EVENTS_START: 'FETCH_EVENTS_START',
  FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_FAILURE: 'FETCH_EVENTS_FAILURE',

  ADD_EVENT_START: 'ADD_EVENT_START',
  ADD_EVENT_SUCCESS: 'ADD_EVENT_SUCCESS',
  ADD_EVENT_FAILURE: 'ADD_EVENT_FAILURE',

  EDIT_EVENT_START: 'EDIT_EVENT_START',
  EDIT_EVENT_SUCCESS: 'EDIT_EVENT_SUCCESS',
  EDIT_EVENT_FAILURE: 'EDIT_EVENT_FAILURE',

  DELETE_EVENT_START: 'DELETE_EVENT_START',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FAILURE: 'DELETE_EVENT_FAILURE'
};

export default eventTypes;
