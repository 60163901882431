import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Menu, MenuItem, CardActionArea } from '@material-ui/core';
import { trimString } from 'utils/strings';
import useStyles from './styles';

const EventCard = ({ event, onEditEvent, onDeleteEvent }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              S
            </Avatar>
          }
          action={
            <Fragment>
              <IconButton aria-label="settings" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu id="fade-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    onEditEvent();
                    handleClose();
                  }}
                >
                  <EditIcon className={classes.icons} color="primary" />
                  <Typography variant="inherit">Edit</Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    onDeleteEvent();
                    handleClose();
                  }}
                >
                  <DeleteIcon className={classes.icons} color="error" />
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
              </Menu>
            </Fragment>
          }
          title={event.name}
          subheader={new Date(event.startDate).toLocaleDateString()}
        />
        <CardActionArea>
          <CardMedia className={classes.media} image={event.image || '/images/santarunningbanner.jpg'} title="Running Race" />
          <CardContent className={classes.cardContent}>
            <Typography variant="body2" color="textSecondary" component="p">
              {trimString(event.description, 150)}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.actions} style={{ display: 'none' }} disableSpacing>
          <div>
            <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </>
  );
};

EventCard.propTypes = {
  event: PropTypes.object,
  onEditEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func
};

export default EventCard;
