import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  modeTitle: {
    fontSize: '2rem',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    padding: theme.spacing(1, 3)
  }
}));
