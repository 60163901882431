import claimTypes from './claim_types';

export const fetchClaimsStart = () => ({
  type: claimTypes.FETCH_CLAIMS_START
});

export const fetchClaimsSuccess = (athletes) => ({
  type: claimTypes.FETCH_CLAIMS_SUCCESS,
  payload: athletes
});

export const createClaimsStart = (claimObject) => ({
  type: claimTypes.CREATE_CLAIM_START,
  payload: claimObject
});

export const createClaimsSuccess = (claim) => ({
  type: claimTypes.CREATE_CLAIM_SUCCESS,
  payload: claim
});

export const saveClaimStart = (athleteId, userId, claimId) => ({
  type: claimTypes.SAVE_CLAIM_START,
  payload: { athleteId, userId, claimId }
});

export const claimsFailure = (error) => ({
  type: claimTypes.CLAIMS_FAILURE,
  payload: error
});
