import { takeLatest, put, all, call } from 'redux-saga/effects';
import eventsTypes from './event_types';
import {
  fetchEventsSuccess,
  fetchEventsFailure,
  addEventSuccess,
  addEventFailure,
  deleteEventSuccess,
  deleteEventFailure,
  fetchEventsStart
} from './event_actions';
import { setAuthHeaders } from 'utils';
import { setAlertStart } from 'store/alert/alert_actions';

export function* fetchEvents() {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/events`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();
    yield put(fetchEventsSuccess(responseData));
  } catch (error) {
    yield put(fetchEventsFailure(error));
    console.log(error);
  }
}

export function* addEvent({ payload: event }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/events`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(event)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(addEventSuccess(responseData));
      yield put(setAlertStart('Event Created', 'success', 5000));
      yield put(fetchEventsStart());
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(setAlertStart('something went wrong', 'error', 5000));
    yield put(addEventFailure(error));
    console.log(error);
  }
}

export function* editEvent({ payload: event }) {
  const { _id, createdAt, updatedAt, __v, ...rest } = event;
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/events/${_id}`;
    const response = yield fetch(url, {
      method: 'PATCH',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(rest)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(addEventSuccess(responseData));
      yield put(setAlertStart('Event Updated', 'success', 5000));
      yield put(fetchEventsStart());
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(setAlertStart('something went wrong', 'error', 5000));
    yield put(addEventFailure(error));
    console.log(error);
  }
}

export function* deleteEvent({ payload: eventId }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/events/${eventId}`;
    const response = yield fetch(url, {
      method: 'DELETE',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' })
    });
    if (response.ok) {
      yield put(setAlertStart('Event Deleted', 'success', 5000));
      yield put(deleteEventSuccess(eventId));
    }
  } catch (error) {
    yield put(deleteEventFailure(error));
    console.log(error);
  }
}

export function* onFetchEventsStart() {
  yield takeLatest(eventsTypes.FETCH_EVENTS_START, fetchEvents);
}

export function* onAddEventStart() {
  yield takeLatest(eventsTypes.ADD_EVENT_START, addEvent);
}

export function* onEditEventStart() {
  yield takeLatest(eventsTypes.EDIT_EVENT_START, editEvent);
}

export function* onDeleteEventStart() {
  yield takeLatest(eventsTypes.DELETE_EVENT_START, deleteEvent);
}

export function* eventSagas() {
  yield all([call(onFetchEventsStart), call(onAddEventStart), call(onEditEventStart), call(onDeleteEventStart)]);
}
