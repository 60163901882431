/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as userSelectors from 'store/user/user_selectors';
import * as claimActions from 'store/claim/claim_actions';
import * as claimSelectors from 'store/claim/claim_selectors';
import { Grid, Typography, Box } from '@material-ui/core';
import useStyles from './styles';
import Entry from './Entry';

const structuredSelector = createStructuredSelector({
  isAuthenticated: userSelectors.getAuthenticatedUser,
  user: userSelectors.getCurrentUser,
  currentUserClaim: claimSelectors.getCurrentUserClaim
});

const UserResultDialog = ({ isDesktop, open, handleClose, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAuthenticated, user, currentUserClaim } = useSelector(structuredSelector);
  const claimByAthleteId = useSelector((state) => claimSelectors.getClaimByAthleteId(state, data && data.athleteId));

  const completedClaim = claimByAthleteId && claimByAthleteId.status === '"completed"';
  const isClaimed = Boolean(currentUserClaim || completedClaim);

  const handleClaim = async () => {
    const { athleteId } = data;
    const userId = user._id;
    dispatch(claimActions.createClaimsStart({ athleteId, userId }));
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(claimActions.fetchClaimsStart());
    }
  }, [isAuthenticated]);

  if (!data) return null;
  return (
    <Dialog fullScreen={!isDesktop} fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogContent style={{ overflowX: 'hidden', marginTop: 20 }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Entry
              title="Στοιχεία"
              head={[
                { id: 'firstname', text: 'Όνομα' },
                { id: 'lastname', text: 'Επώνυμο' }
              ]}
              data={data}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Entry
              title="Κατηγορία"
              head={[
                { id: 'group', text: 'Ομάδα' },
                { id: 'ageCategory', text: 'Ηλικιακή' },
                { id: 'sexCategory', text: 'Φύλλο' }
              ]}
              data={data}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Entry
              title="Αποτελέσματα"
              head={[
                { id: 'distance', text: 'Απόσταση' },
                { id: 'totalTime', text: 'Συνολικός Χρόνος' },
                { id: 'tempo', text: 'Μέσος Χρόνος' },
                { id: 'elevation', text: 'Υψόμετρική Διαφορά' }
              ]}
              data={data}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Entry
              title="Κατάταξη"
              head={[
                { id: 'generalRanking', text: 'Γενική' },
                { id: 'ageRanking', text: 'Ηλικιακή' },
                { id: 'sexRanking', text: 'Φύλλο' }
              ]}
              data={data}
            />
          </Grid>
          {!isClaimed && (
            <Grid item xs={12}>
              {isAuthenticated ? (
                <Box width={!isDesktop ? '90%' : '50%'} margin="15px auto">
                  <Typography className={classes.entryTitle} variant="h5">
                    Κάνετε Claim τον αθλητή
                  </Typography>
                  <Button fullWidth className={classes.button} color="primary" onClick={handleClaim} disabled={isClaimed}>
                    Claim
                  </Button>
                </Box>
              ) : (
                <Box width={!isDesktop ? '90%' : '50%'} margin="15px auto">
                  <Typography className={classes.entryTitle} variant="h5">
                    Συνδεθείτε για να κάνετε Claim τον αθλητή
                  </Typography>
                  <Button fullWidth className={classes.button} color="primary" component={Link} to="/register">
                    Συνδεθείτε
                  </Button>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserResultDialog.propTypes = {
  isDesktop: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object
};

export default UserResultDialog;
