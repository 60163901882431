import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  composition: {
    position: 'relative'
  },
  photo: {
    width: '55%',
    boxShadow: '0 1.5rem 4rem rgba(0, 0, 0, 0.4)',
    borderRadius: 2,
    position: 'absolute',
    zIndex: 10,
    transition: 'all 0.2s',
    outlineOffset: '2rem',
    '&:hover': {
      outline: '1.5rem solid rgb(80, 128, 60)',
      transform: 'scale(1.05) translateY(-0.5rem)',
      boxShadow: '0 2.5rem 4rem rgba(0, 0, 0, 0.5)',
      zIndex: 20
    }
  },
  photo1: { left: 0, top: '-2rem' },
  photo2: { right: 0, top: '2rem' },
  photo3: { left: '20%', top: '10rem' },
  [theme.breakpoints.down('sm')]: {
    composition: { marginTop: theme.spacing(10) },
    photo: {
      position: 'relative',
      float: 'left',
      width: '33.33333333%',
      boxShadow: '0 1.5rem 3rem rgba(0, 0, 0, 0.2)'
    },
    photo1: { top: 0, transform: 'scale(1.2)' },
    photo2: { top: '-1rem', transform: 'scale(1.3)', zIndex: 100 },
    photo3: { top: '1rem', left: 0, transform: 'scale(1.1)' }
  }
}));
