/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Paper, Typography, Divider, Grid, TextField, Button, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as athleteActions from 'store/athlete/athlete_actions';
import * as athleteSelectors from 'store/athlete/athlete_selectors';
import useStyles from './styles';

const ProfileDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentAthlete = useSelector(athleteSelectors.getCurrentAthlete);

  const [userProfile, setUserProfile] = useState({
    athleteId: '',
    firstname: '',
    lastname: '',
    birthdate: '',
    sex: 'M',
    height: '',
    weight: '',
    phone: '',
    city: '',
    state: '',
    country: '',
    ageCategory: 'N/A'
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserProfile({ ...userProfile, [name]: value });
  };

  useEffect(() => {
    dispatch(athleteActions.fetchMyAthleteProfileStart());
  }, [dispatch]);

  useEffect(() => {
    if (currentAthlete) {
      setUserProfile({ ...userProfile, ...currentAthlete });
    }
  }, [currentAthlete]);

  const handleSaveProfile = () =>
    currentAthlete
      ? dispatch(athleteActions.updateAthleteProfileStart(userProfile))
      : dispatch(athleteActions.createAthleteProfileStart(userProfile));

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4">Profile</Typography>
      <Typography variant="body1">The information can be edited</Typography>
      <Divider className={classes.divider} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            helperText="Please specify the athlete id"
            label="Athlete Id"
            name="athleteId"
            value={userProfile.athleteId}
            variant="outlined"
            onChange={handleChange}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            helperText="Please specify the first name"
            label="First Name"
            name="firstname"
            value={userProfile.firstname}
            variant="outlined"
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            helperText="Please specify the last name"
            label="Last Name"
            name="lastname"
            value={userProfile.lastname}
            variant="outlined"
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            helperText="Please specify the birthdate"
            type="date"
            label="Birthdate"
            name="birthdate"
            value={userProfile.birthdate}
            variant="outlined"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            select
            variant="outlined"
            label="Age Category"
            name="ageCategory"
            value={userProfile.ageCategory}
            onChange={handleChange}
            required
          >
            {['N/A', 'M', 'M30', 'M40', 'M50', 'M60', 'F', 'F30', 'F40', 'F50', 'F60'].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            select
            helperText="Please specify the sex"
            label="Sex"
            name="sex"
            value={userProfile.sex}
            variant="outlined"
            onChange={handleChange}
          >
            {[
              { value: 'M', text: 'Male' },
              { value: 'F', text: 'Female' },
              { value: 'O', text: 'Other' }
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            helperText="Please specify the height"
            label="Height"
            name="height"
            type="number"
            value={userProfile.height}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            helperText="Please specify the weight"
            label="Weight"
            name="weight"
            type="number"
            value={userProfile.weight}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            helperText="Please specify the phone number"
            label="Phone Number"
            name="phone"
            value={userProfile.phone}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            helperText="Please specify the city"
            label="City"
            name="city"
            value={userProfile.city}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            helperText="Please specify the state"
            label="State"
            name="state"
            value={userProfile.state}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            helperText="Please specify the country"
            label="Country"
            name="country"
            value={userProfile.country}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Button onClick={handleSaveProfile}>Save Profile</Button>
    </Paper>
  );
};

export default ProfileDetails;
