import athleteTypes from './athlete_types';

const INITIAL_STATE = {
  athletes: [],
  selectedAthlete: null,
  loading: false,
  error: null
};

const athleteReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case athleteTypes.FETCH_ATHLETES_PROFILE_SUCCESS:
      return {
        ...state,
        athletes: payload,
        loading: false,
        error: null
      };
    case athleteTypes.FETCH_ATHLETE_PROFILE_SUCCESS:
      return {
        ...state,
        selectedAthlete: payload,
        loading: false,
        error: null
      };
    case athleteTypes.ATHLETES_PROFILE_FAILURE:
      return { ...INITIAL_STATE, error: payload, loading: false };
    default:
      return state;
  }
};

export default athleteReducer;
