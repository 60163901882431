import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Grid, Button, Typography } from '@material-ui/core';
import { getToken } from 'utils';
import { setAlertStart } from 'store/alert/alert_actions';
import useStyles from './styles';

const AddGroup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: ''
  });

  const { name } = form;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  // TODO: move to sagas
  const handleSave = async () => {
    try {
      const token = getToken();
      const url = `${process.env.REACT_APP_API_PROXY}/api/groups`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(form)
      });
      const responseData = await response.json();
      if (response.ok) {
        dispatch(setAlertStart('Group Added', 'success', 5000));
      } else {
        dispatch(setAlertStart(responseData.message, 'error', 5000));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid className={classes.root} container spacing={3} alignItems="flex-end">
      <Grid item xs={12}>
        <Typography className={classes.modeTitle} variant="h2">
          Add New Group
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField fullWidth label="Group Name" name="name" value={name} onChange={handleChange} />
      </Grid>

      <Grid item xs={6} md={6}>
        <Button fullWidth className={classes.button} variant="contained" color="primary" onClick={handleSave}>
          Add Group
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddGroup;
