import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import RaceResultsTableHead from '../RaceResultsTableHead/RaceResultsTableHead';
import RaceResultsTableToolbar from '../RaceResultsTableToolbar/RaceResultsTableToolbar';
import useStyles from './styles';
import AddRaceResult from '../AddRaceResult/AddRaceResult';
import { deleteRaceResultStart } from 'store/race/race_actions';
import { useDispatch } from 'react-redux';
import AddAthlete from '../AddAthlete/AddAthlete';
import { fetchAthletesProfileStart } from 'store/athlete/athlete_actions';
import AddGroup from '../AddGroup/AddGroup';
import { setAuthHeaders } from 'utils';

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getSorting = (order, orderBy) => (order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy));

const RaceResultsTable = ({ results, raceId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('athleteId');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [showAddResult, setShowAddResult] = useState(false);
  const [showAddAthlete, setShowAddAthlete] = useState(false);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [groups, setGroups] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = results.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, results.length - page * rowsPerPage);

  const onShowAddResult = () => {
    setSelected([]);
    setShowAddResult(!showAddResult);
  };
  const onEditSelected = () => {
    setShowAddResult(!showAddResult);
  };
  const onDeleteSelected = () => {
    dispatch(deleteRaceResultStart(selected));
  };

  const onShowAddAthlete = () => {
    setShowAddAthlete(!showAddAthlete);
  };

  const onShowAddGroup = () => {
    setShowAddGroup(!showAddGroup);
  };

  const fetchGroups = async () => {
    try {
      const url = `${process.env.REACT_APP_API_PROXY}/api/groups`;
      const response = await fetch(url, {
        method: 'GET',
        headers: setAuthHeaders()
      });
      const responseData = await response.json();
      setGroups(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(fetchAthletesProfileStart());
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedRace = results.find((race) => race._id === selected[0]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <RaceResultsTableToolbar
          numSelected={selected.length}
          onShowAddResult={onShowAddResult}
          onDeleteSelected={onDeleteSelected}
          onEditSelected={onEditSelected}
          onShowAddAthlete={onShowAddAthlete}
          onShowAddGroup={onShowAddGroup}
        />
        {showAddGroup && <AddGroup />}
        {showAddAthlete && <AddAthlete />}
        {showAddResult && <AddRaceResult selectedRace={selectedRace} raceId={raceId} groups={groups} />}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="race-results table"
          >
            <RaceResultsTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={results.length}
            />
            <TableBody>
              {stableSort(results, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `race-results-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.athleteId}
                      </TableCell>
                      <TableCell align="right">{row.firstname}</TableCell>
                      <TableCell align="right">{row.lastname}</TableCell>
                      <TableCell align="right">{row.ageCategory}</TableCell>
                      <TableCell align="right">{row.group}</TableCell>
                      <TableCell align="right">{row.distance}</TableCell>
                      <TableCell align="right">{row.totalTime}</TableCell>
                      <TableCell align="right">{row.tempo}</TableCell>
                      <TableCell align="right">{row.elevation}</TableCell>
                      <TableCell align="right">{row.generalRanking}</TableCell>
                      <TableCell align="right">{row.sexRanking}</TableCell>
                      <TableCell align="right">{row.ageRanking}</TableCell>
                      <TableCell align="right">{row.points}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={results.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
    </div>
  );
};

RaceResultsTable.propTypes = { results: PropTypes.array, raceId: PropTypes.string };

export default RaceResultsTable;
