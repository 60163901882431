import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TablePagination, Button } from '@material-ui/core';

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  paper: { marginBottom: theme.spacing(10) },
  tableHead: { background: 'rgb(80, 128, 60)' },
  tableCell: {
    fontSize: 10,
    color: '#fff'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  button: {
    minWidth: 200,
    margin: theme.spacing(2, 0),
    color: '#fff',
    padding: theme.spacing(2),
    textTransform: 'capitalize',
    backgroundColor: 'rgb(80, 128, 60)',
    '&:hover': {
      backgroundColor: 'rgb(255, 152, 52)'
    }
  }
}));

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function Row(props) {
  const { row, onSelectResult } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.generalRanking}
        </TableCell>
        <TableCell>
          <Typography variant="body1">{row.fullname}</Typography>
          <Typography variant="caption">
            {row.ageCategory}-{row.group}
          </Typography>
        </TableCell>
        <TableCell>{row.tempo}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Αποτελέσματα
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Φύλλο</TableCell>
                    <TableCell>Ηλικία</TableCell>
                    <TableCell>Απόσταση</TableCell>
                    <TableCell>Χρόνος</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.sexRanking}
                    </TableCell>
                    <TableCell>{row.ageRanking}</TableCell>
                    <TableCell>{row.distance}</TableCell>
                    <TableCell>{row.totalTime}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Button className={classes.button} onClick={() => onSelectResult(row)}>
                Περισσότερα
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ results, onSelectResult }) {
  const classes = useRowStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('generalRanking');
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, results.length - page * rowsPerPage);

  return (
    <TableContainer component={Paper} className={classes.paper}>
      <Table aria-label="collapsible table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableCell} />
            <TableCell className={classes.tableCell} sortDirection={orderBy === 'generalRanking' ? order : false}>
              <TableSortLabel
                active={orderBy === 'generalRanking'}
                direction={orderBy === 'generalRanking' ? order : 'asc'}
                onClick={() => handleRequestSort('generalRanking')}
              >
                Γεν Κατάταξη
                {orderBy === 'generalRanking' ? (
                  <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableCell}>
              <Typography variant="body1">Όνομα</Typography>
              <Typography variant="caption">(Ηλικιακή Κατηγορία - Ομάδα)</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <TableSortLabel
                active={orderBy === 'totalTime'}
                direction={orderBy === 'totalTime' ? order : 'asc'}
                onClick={() => handleRequestSort('totalTime')}
              >
                Μέσος Χρόνος
                {orderBy === 'totalTime' ? (
                  <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(results, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <Row key={row.generalRanking} row={row} onSelectResult={onSelectResult} />
            ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={results.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
