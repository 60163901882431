import teamTypes from './team_types';

const INITIAL_STATE = {
  teams: [],
  loading: false,
  error: null
};

const teamReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case teamTypes.FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        teams: payload,
        loading: false,
        error: null
      };

    case teamTypes.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        teams: state.team.filter((team) => team._id !== payload),
        loading: false,
        error: null
      };
    case teamTypes.FETCH_TEAMS_FAILURE:
    case teamTypes.ADD_TEAM_FAILURE:
    case teamTypes.DELETE_TEAM_FAILURE:
      return { ...INITIAL_STATE, error: payload };
    default:
      return state;
  }
};

export default teamReducer;
