import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Grid, Button, MenuItem, Typography } from '@material-ui/core';
import { addRaceResultStart, editRaceResultStart } from 'store/race/race_actions';
import { hhmmss_to_seconds, calculatePace } from 'utils/pacecalculator';
import TotalTimeMaskedInput from './TotalTimeMaskedInput';
import useStyles from './styles';

const INITIAL_FORM = {
  athleteId: '',
  bib: '',
  firstname: '',
  lastname: '',
  generalRanking: '',
  sexRanking: '',
  ageRanking: '',
  ageCategory: '',
  distance: '',
  totalTime: '',
  tempo: '',
  elevation: '',
  application: '',
  group: 'N/A',
  points: ''
};

const AddRaceResult = ({ selectedRace, raceId, groups }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { athletes } = useSelector((state) => state.athlete);
  const [form, setForm] = useState(INITIAL_FORM);

  const {
    bib,
    firstname,
    lastname,
    generalRanking,
    sexRanking,
    ageRanking,
    ageCategory,
    distance,
    totalTime,
    tempo,
    elevation,
    points
  } = form;

  const handleChangeAthleteId = (value) => {
    const currentAthlete = athletes.find((athlete) => athlete.athleteId === value);
    if (currentAthlete) {
      const { firstname, lastname, ageCategory } = currentAthlete;
      return setForm({ ...form, firstname, lastname, ageCategory, athleteId: value });
    }
  };

  const handleChangeGroup = ({ name }) => {
    setForm({ ...form, group: name });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'athleteId') {
      const currentAthlete = athletes.find((athlete) => athlete.athleteId === value);
      if (currentAthlete) {
        const { firstname, lastname } = currentAthlete;
        return setForm({ ...form, firstname, lastname, [name]: value });
      }
    }
    setForm({ ...form, [name]: value });
  };

  const handleSaveResult = () => {
    if (selectedRace) {
      const { _id } = selectedRace;
      dispatch(editRaceResultStart({ ...form, raceId }, _id));
    } else {
      dispatch(addRaceResultStart({ ...form, raceId }));
    }
  };

  useEffect(() => {
    if (selectedRace) {
      // eslint-disable-next-line no-unused-vars
      const { createdAt, updatedAt, __v, _id, ...rest } = selectedRace;
      setForm(rest);
    } else {
      setForm(INITIAL_FORM);
    }
  }, [selectedRace]);

  const onCalculatePace = () => {
    if (distance && totalTime) {
      const [h, m, s] = totalTime.split(':');
      const totalSeconds = hhmmss_to_seconds(h, m, s);
      const calculatedPace = calculatePace(totalSeconds, distance);
      setForm({ ...form, tempo: `${calculatedPace.m}:${calculatedPace.s}` });
    }
  };

  useEffect(() => {
    onCalculatePace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalTime, distance]);

  const athleteIdOptions = athletes.map((athlete) => athlete.athleteId).filter(Boolean);

  const isValid = firstname && lastname && ageCategory && distance && totalTime && tempo;

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.modeTitle} variant="h2">
          {selectedRace ? 'Edit ' : 'Add '} Mode
        </Typography>
      </Grid>
      {!!athleteIdOptions.length && (
        <Grid item xs={6} md={3}>
          <Autocomplete
            options={athleteIdOptions}
            getOptionLabel={(option) => option}
            onChange={(event, value) => handleChangeAthleteId(value)}
            renderInput={(params) => <TextField {...params} label="Athelete Id" />}
          />
        </Grid>
      )}

      <Grid item xs={6} md={3}>
        <TextField fullWidth label="Bib Id" name="bib" value={bib} onChange={handleChange} />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth label="First Name" name="firstname" value={firstname} onChange={handleChange} required />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth label="Last Name" name="lastname" value={lastname} onChange={handleChange} required />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth select label="Age Category" name="ageCategory" value={ageCategory} onChange={handleChange} required>
          {['N/A', 'M', 'M30', 'M40', 'M50', 'M60', 'F', 'F30', 'F40', 'F50', 'F60'].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {!!groups.length && (
        <Grid item xs={6} md={3}>
          <Autocomplete
            options={groups}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => handleChangeGroup(value)}
            renderInput={(params) => <TextField {...params} label="Group" />}
          />
        </Grid>
      )}
      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          label="Distance"
          name="distance"
          placeholder="ex. 5.01"
          value={distance}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          label="Total Time"
          name="totalTime"
          value={totalTime}
          onChange={handleChange}
          InputProps={{
            inputComponent: TotalTimeMaskedInput
          }}
          required
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth label="tempo (min/km)" name="tempo" value={tempo} onChange={handleChange} required />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth label="Elevation" name="elevation" value={elevation} onChange={handleChange} />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          type="number"
          label="General Ranking"
          name="generalRanking"
          value={generalRanking}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth type="number" label="Sex Ranking" name="sexRanking" value={sexRanking} onChange={handleChange} />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth type="number" label="Age Ranking" name="ageRanking" value={ageRanking} onChange={handleChange} />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth type="number" label="Points" name="points" value={points} onChange={handleChange} />
      </Grid>
      <Grid item xs={12} className={classes.flexEnd}>
        <Button disabled={!isValid} className={classes.button} variant="contained" color="primary" onClick={handleSaveResult}>
          Add Result
        </Button>
      </Grid>
    </Grid>
  );
};

AddRaceResult.propTypes = { selectedRace: PropTypes.object, raceId: PropTypes.string, groups: PropTypes.array };

export default AddRaceResult;
