const claimTypes = {
  FETCH_CLAIMS_START: 'FETCH_CLAIMS_START',
  FETCH_CLAIMS_SUCCESS: 'FETCH_CLAIMS_SUCCESS',
  CREATE_CLAIM_START: 'CREATE_CLAIM_START',
  CREATE_CLAIM_SUCCESS: 'CREATE_CLAIM_SUCCESS',
  SAVE_CLAIM_START: 'SAVE_CLAIM_START',
  SAVE_CLAIM_SUCCESS: 'SAVE_CLAIM_SUCCESS',

  CLAIMS_FAILURE: 'CLAIMS_FAILURE'
};

export default claimTypes;
