import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ColoredButton = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <a className={classes.button} {...rest}>
      {children}
    </a>
  );
};

ColoredButton.propTypes = { children: PropTypes.any };

export default ColoredButton;
