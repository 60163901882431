import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  button: {
    fontSize: theme.spacing(2),
    color: 'rgb(80, 128, 60)',
    display: 'inline-block',
    textDecoration: 'none',
    borderBottom: '1px solid rgb(80, 128, 60)',
    padding: 8,
    transition: 'all 0.5s',
    '&:hover': {
      backgroundColor: 'rgb(80, 128, 60)',
      color: '#fff',
      boxShadow: '0 1rem 2rem rgba(0, 0, 0, 0.15)',
      transform: 'translateY(-2px)'
    },
    '&:active': {
      boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
      transform: 'translateY(0)'
    }
  }
}));
