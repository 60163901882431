import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/user/user_selectors';
import { Avatar, Typography } from '@material-ui/core';
import useStyles from './styles';

const SimpleProfile = () => {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);

  return (
    <div className={classes.root}>
      <Avatar alt="User Image" className={classes.avatar} component={Link} src={currentUser.avatar} to="/" />
      <Typography className={classes.name} variant="h4">
        {currentUser.username}
      </Typography>
      <Typography variant="body2">{currentUser.email}</Typography>
    </div>
  );
};

export default SimpleProfile;
