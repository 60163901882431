import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
export default makeStyles((theme) => ({
  card: {
    width: '100%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  avatar: {
    backgroundColor: green[500]
  },
  icons: {
    marginRight: theme.spacing(2)
  },
  cardContent: {
    height: 100,
    overflowY: 'auto'
  },
  actions: {
    padding: theme.spacing(1, 3),
    justifyContent: 'space-between'
  }
}));
