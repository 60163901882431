import { all, call } from 'redux-saga/effects';
import { alertSagas } from './alert/alert_sagas';
import { userSagas } from './user/user_sagas';
import { raceSagas } from './race/race_sagas';
import { athleteSagas } from './athlete/athlete_sagas';
import { eventSagas } from './event/event_sagas';
import { teamSagas } from './team/team_sagas';
import { claimSagas } from './claim/claim_sagas';

export default function* rootSaga() {
  yield all([
    call(alertSagas),
    call(userSagas),
    call(raceSagas),
    call(athleteSagas),
    call(eventSagas),
    call(teamSagas),
    call(claimSagas)
  ]);
}
