import React from 'react';
import './styles.css';

const Navigation = ({ isAuthenticated, handleLogout }) => (
  <div className="navigation">
    <input type="checkbox" className="navigation__checkbox" id="navi-toggle" />
    <label htmlFor="navi-toggle" className="navigation__button">
      <span className="navigation__icon">&nbsp;</span>
    </label>
    <div className="navigation__background">&nbsp;</div>
    <nav className="navigation__nav">
      <ul className="navigation__list">
        <li className="navigation__item">
          <a className="navigation__link" href="/">
            <span>01</span> Home Page
          </a>
        </li>
        <li className="navigation__item">
          <a
            className="navigation__link"
            href="https://www.facebook.com/SantaRunningTeam/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>02</span>Facebook Page
          </a>
        </li>
        <li className="navigation__item">
          <a
            className="navigation__link"
            href="https://www.strava.com/clubs/santarunningteam"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>03</span>Strava Page
          </a>
        </li>
        <li className="navigation__item">
          <a
            className="navigation__link"
            href="https://connect.garmin.com/modern/group/2588006"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>04</span>Garmin Page
          </a>
        </li>

        <li className="navigation__item">
          <a
            className="navigation__link"
            href="https://www.instagram.com/santarunningteam/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>05</span>instagram Page
          </a>
        </li>

        {isAuthenticated ? (
          <>
            <li className="navigation__item">
              <a className="navigation__link" href="/profile">
                <span>06</span>My Profile
              </a>
            </li>
            <li className="navigation__item">
              <a className="navigation__link" href="/" onClick={handleLogout}>
                <span>07</span>Logout
              </a>
            </li>
          </>
        ) : (
          <li className="navigation__item">
            <a className="navigation__link" href="/login">
              <span>06</span>Login
            </a>
          </li>
        )}
      </ul>
    </nav>
  </div>
);

export default Navigation;
