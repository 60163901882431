import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getRaces } from 'store/race/race_selectors';
import { fetchRacesStart, deleteRaceStart } from 'store/race/race_actions';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import RaceCard from 'pages/admin/Races/components/RaceCard/RaceCard';
import AddRace from './components/AddRace/AddRace';
import useStyles from './styles';

const structuredSelector = createStructuredSelector({
  races: getRaces
});

const Races = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { races } = useSelector(structuredSelector);

  const [showAddRace, setShowAddRace] = useState(false);
  const [editedRace, setEditedRace] = useState(null);

  useEffect(() => {
    dispatch(fetchRacesStart());
  }, [dispatch]);

  const onAddRace = () => {
    setShowAddRace(!showAddRace);
    setEditedRace(null);
  };

  const onEditRace = (id) => {
    const selectedRace = races && races.find((race) => race._id === id);
    setEditedRace(selectedRace);
    setShowAddRace(true);
  };

  const onDeleteRace = (id) => dispatch(deleteRaceStart(id));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.flex}>
              <Typography className={classes.title}>Races</Typography>
              <span className={classes.seperator}></span>
              {races && <Typography className={classes.details}>{races.length} Total</Typography>}
            </div>
            <Button className={classes.button} onClick={onAddRace}>
              {showAddRace ? 'Close Race Form' : 'Add Race'}
            </Button>
          </div>
        </Paper>
      </Grid>
      {showAddRace && (
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <AddRace editedRace={editedRace} />
          </Paper>
        </Grid>
      )}
      {races &&
        races.map((race) => (
          <Grid key={race._id} item xs={12} md={6} lg={3}>
            <RaceCard race={race} onEdit={() => onEditRace(race._id)} onDelete={() => onDeleteRace(race._id)} />
          </Grid>
        ))}
    </Grid>
  );
};

export default Races;
