import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  section: {
    backgroundColor: '#fff',
    padding: theme.spacing(10, 0)
  },
  header: {
    height: '80vh',
    position: 'relative',
    backgroundImage:
      'linear-gradient(to right bottom, rgba(0, 0, 0, 0.5), rgba(80, 128, 60, 0.6)),url(/images/6.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    clipPath: 'polygon(0 0, 100% 0, 100% 70vh, 0 100%)'
  },
  logoContainer: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4)
  },
  logo: {
    height: theme.spacing(7)
  },
  textContainer: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: ' translate(-50%, -50%)',
    textAlign: 'center'
  },
  headingTertiary: {
    color: '#777',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2)
  },
  paragraph: {
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}));
