import React from 'react';
import IntroSection from '../components/IntroSection/IntroSection';
import EventSection from '../components/EventSection/EventSection';

const HomePage = () => (
  <>
    <IntroSection />
    <EventSection />
  </>
);

export default HomePage;
