import React from 'react';
import './styles.css';
import { Grid, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="footer">
    <div className="footer__logo-box">
      <Link to="/">
        <picture className="footer__logo">
          <img alt="Full logo" src="/images/logo_round.png" width={250} />
        </picture>
      </Link>
    </div>
    <Container>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <div className="footer__navigation">
            <ul className="footer__list">
              <li className="footer__item">
                <a
                  href="https://www.facebook.com/SantaRunningTeam/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__link"
                >
                  Facebook
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="https://www.strava.com/clubs/santarunningteam"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__link"
                >
                  Strava
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="https://connect.garmin.com/modern/group/2588006"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__link"
                >
                  Garmin
                </a>
              </li>
              <li className="footer__item">
                <a
                  href="https://www.instagram.com/santarunningteam/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__link"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <p className="footer__copyright">
            &copy; 2020 |{' '}
            <a href="http://georgesimos.com" className="footer__link">
              George Simos
            </a>
          </p>
        </Grid>
      </Grid>
    </Container>
  </footer>
);

export default Footer;
