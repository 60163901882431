import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  container: {
    marginTop: ({ marginTop }) => (marginTop ? theme.spacing(marginTop) : 0),
    marginBottom: ({ marginBottom }) => (marginBottom ? theme.spacing(marginBottom) : theme.spacing(13)),
    textAlign: 'center'
  },
  heading: {
    fontSize: theme.spacing(5),
    textTransform: 'uppercase',
    fontWeight: 700,
    display: 'inline-block',
    backgroundImage: 'linear-gradient(to right, rgb(80, 128, 60), rgb(255, 152, 52))',
    '-webkit-background-clip': 'text',
    color: 'transparent',
    letterSpacing: '0.2rem',
    transition: 'all 0.2s'
  },
  [theme.breakpoints.down('sm')]: {
    heading: {
      fontSize: theme.spacing(3)
    }
  }
}));
