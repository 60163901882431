import eventTypes from './event_types';

export const fetchEventsStart = () => ({
  type: eventTypes.FETCH_EVENTS_START
});

export const fetchEventsSuccess = (events) => ({
  type: eventTypes.FETCH_EVENTS_SUCCESS,
  payload: events
});

export const fetchEventsFailure = (error) => ({
  type: eventTypes.FETCH_EVENTS_FAILURE,
  payload: error
});

export const addEventStart = (event) => ({
  type: eventTypes.ADD_EVENT_START,
  payload: event
});

export const addEventSuccess = (event) => ({
  type: eventTypes.ADD_EVENT_SUCCESS,
  payload: event
});

export const addEventFailure = (error) => ({
  type: eventTypes.ADD_EVENT_FAILURE,
  payload: error
});

export const editEventStart = (event) => ({
  type: eventTypes.EDIT_EVENT_START,
  payload: event
});

export const editEventSuccess = (event) => ({
  type: eventTypes.EDIT_EVENT_SUCCESS,
  payload: event
});

export const editEventFailure = (error) => ({
  type: eventTypes.EDIT_EVENT_FAILURE,
  payload: error
});

export const deleteEventStart = (eventId) => ({
  type: eventTypes.DELETE_EVENT_START,
  payload: eventId
});

export const deleteEventSuccess = (event) => ({
  type: eventTypes.DELETE_EVENT_SUCCESS,
  payload: event
});

export const deleteEventFailure = (error) => ({
  type: eventTypes.DELETE_EVENT_FAILURE,
  payload: error
});
