import { takeLatest, put, all, call } from 'redux-saga/effects';
import racesTypes from './race_types';
import * as raceActions from './race_actions';
import { setAuthHeaders } from 'utils';
import { setAlertStart } from 'store/alert/alert_actions';

export function* fetchRaces() {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/races`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();
    yield put(raceActions.fetchRacesSuccess(responseData));
  } catch (error) {
    yield put(raceActions.fetchRacesFailure(error));
  }
}

export function* addRace({ payload: race }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/races`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(race)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(raceActions.addRaceSuccess(responseData));
      yield put(raceActions.fetchRacesStart());
      yield put(setAlertStart('Race Created', 'success', 5000));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(setAlertStart('something went wrong', 'error', 5000));
    yield put(raceActions.addRaceFailure(error));
  }
}

export function* editRace({ payload: race }) {
  const { _id, __v, createdAt, updatedAt, websiteUrl, ...rest } = race;
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/races/${_id}`;
    const response = yield fetch(url, {
      method: 'PATCH',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(rest)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(raceActions.fetchRacesStart());
      yield put(raceActions.addRaceSuccess(responseData));
      yield put(setAlertStart('Race Edited', 'success', 5000));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(setAlertStart('something went wrong', 'error', 5000));
    yield put(raceActions.addRaceFailure(error));
  }
}

export function* deleteRace({ payload: raceId }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/races/${raceId}`;
    const response = yield fetch(url, {
      method: 'DELETE',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' })
    });
    if (response.ok) {
      yield put(raceActions.fetchRacesStart());
      yield put(setAlertStart('Race Deleted', 'success', 5000));
      yield put(raceActions.deleteRaceSuccess(raceId));
    }
  } catch (error) {
    yield put(raceActions.deleteRaceFailure(error));
  }
}

export function* registerUserOnRace({ payload: { raceId, userId } }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/races/${raceId}/register/${userId}`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' })
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(setAlertStart('You have been register', 'success', 5000));
      yield put(raceActions.registerUserOnSuccess(responseData));
    } else {
      yield put(setAlertStart('Something went wrong', 'error', 5000));
      yield put(raceActions.registerUserOnFailure(responseData));
    }
  } catch (error) {
    yield put(raceActions.registerUserOnFailure(error));
  }
}

export function* addRaceResult({ payload: result }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/races/results`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(result)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(setAlertStart('Result added', 'success', 5000));
      yield put(raceActions.addRaceResultSuccess(responseData));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 5000));
      yield put(raceActions.addRaceResultFailure(responseData));
    }
  } catch (error) {
    yield put(raceActions.addRaceResultFailure(error));
  }
}

export function* editRaceResults({ payload }) {
  const { result, resultId } = payload;
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/races/results/${resultId}`;
    const response = yield fetch(url, {
      method: 'PATCH',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(result)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(setAlertStart('Result updated', 'success', 5000));
      yield put(raceActions.editRaceResultSuccess(responseData));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 5000));
      yield put(raceActions.editRaceResultFailure(responseData));
    }
  } catch (error) {
    yield put(raceActions.editRaceResultFailure(error));
  }
}

export function* deleteRaceResults({ payload: resultIds }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/races/results/delete`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(resultIds)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(setAlertStart(responseData.message, 'success', 5000));
      yield put(raceActions.deleteRaceResultSuccess(responseData));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 5000));
      yield put(raceActions.deleteRaceResultSuccess(responseData));
    }
  } catch (error) {
    yield put(raceActions.deleteRaceResultSuccess(error));
  }
}

export function* fetchRaceResults({ payload: raceId }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/races/${raceId}/results`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();
    console.log(responseData);
    yield put(raceActions.fetchRaceResultsSuccess(responseData));
  } catch (error) {
    yield put(raceActions.fetchRaceResultsFailure(error));
  }
}

export function* onFetchRacesStart() {
  yield takeLatest(racesTypes.FETCH_RACES_START, fetchRaces);
}

export function* onAddRaceStart() {
  yield takeLatest(racesTypes.ADD_RACE_START, addRace);
}

export function* onEditRaceStart() {
  yield takeLatest(racesTypes.EDIT_RACE_START, editRace);
}

export function* onDeleteRaceStart() {
  yield takeLatest(racesTypes.DELETE_RACE_START, deleteRace);
}

export function* onRegisterUserOnRace() {
  yield takeLatest(racesTypes.REGISTER_USER_ON_RACE_START, registerUserOnRace);
}

export function* onAddRaceResult() {
  yield takeLatest(racesTypes.ADD_RACE_RESULT_START, addRaceResult);
}

export function* onEditRaceResults() {
  yield takeLatest(racesTypes.EDIT_RACE_RESULT_START, editRaceResults);
}

export function* onDeleteRaceResults() {
  yield takeLatest(racesTypes.DELETE_RACE_RESULTS_START, deleteRaceResults);
}

export function* onFetchRaceResults() {
  yield takeLatest(racesTypes.FETCH_RACE_RESULTS_BY_ID_START, fetchRaceResults);
}

export function* raceSagas() {
  yield all([
    call(onFetchRacesStart),
    call(onAddRaceStart),
    call(onEditRaceStart),
    call(onDeleteRaceStart),
    call(onRegisterUserOnRace),
    call(onAddRaceResult),
    call(onEditRaceResults),
    call(onDeleteRaceResults),
    call(onFetchRaceResults)
  ]);
}
