import { takeLatest, put, all, call } from 'redux-saga/effects';
import athleteTypes from './athlete_types';
import * as athleteActions from './athlete_actions';
import { setAlertStart } from 'store/alert/alert_actions';
import { setAuthHeaders } from 'utils';

export function* createAthleteProfile({ payload: athlete }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/profile/`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(athlete)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(athleteActions.createAthletesProfileSuccess(responseData));
      yield put(athleteActions.fetchAthletesProfileSuccess(responseData));
      yield put(setAlertStart('Athlete Created', 'success', 5000));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(athleteActions.athletesProfileFailure(error));
  }
}

export function* updateAthleteProfile({ payload }) {
  try {
    const { _id, ...athlete } = payload;
    const url = `${process.env.REACT_APP_API_PROXY}/api/profile/${_id}`;
    const response = yield fetch(url, {
      method: 'PATCH',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(athlete)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(athleteActions.updateAthletesProfileSuccess(responseData));
      yield put(athleteActions.fetchAthletesProfileSuccess(responseData));
      yield put(setAlertStart('Athlete Updated', 'success', 5000));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(athleteActions.athletesProfileFailure(error));
  }
}

export function* fetchAthletesProfile() {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/profile/`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();

    if (response.ok) {
      yield put(athleteActions.fetchAthletesProfileSuccess(responseData));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(athleteActions.athletesProfileFailure(error));
  }
}

export function* fetchAthleteProfile({ payload: athleteId }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/profile/${athleteId}`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();

    if (response.ok) {
      yield put(athleteActions.fetchAthleteProfileSuccess(responseData));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(athleteActions.athletesProfileFailure(error));
  }
}

export function* fetchMyAthleteProfile() {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/profile/me`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();

    if (response.ok) {
      yield put(athleteActions.fetchAthleteProfileSuccess(responseData));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(athleteActions.athletesProfileFailure(error));
  }
}

export function* onCreateProfileStart() {
  yield takeLatest(athleteTypes.CREATE_ATHLETE_PROFILE_START, createAthleteProfile);
}
export function* onUpdateProfileStart() {
  yield takeLatest(athleteTypes.UPDATE_ATHLETE_PROFILE_START, updateAthleteProfile);
}
export function* onFetchAthletesProfileStart() {
  yield takeLatest(athleteTypes.FETCH_ATHLETES_PROFILE_START, fetchAthletesProfile);
}
export function* onFetchAthleteProfileStart() {
  yield takeLatest(athleteTypes.FETCH_ATHLETE_PROFILE_START, fetchAthleteProfile);
}
export function* onFetchMyAthleteProfileStart() {
  yield takeLatest(athleteTypes.FETCH_MY_ATHLETE_PROFILE_START, fetchMyAthleteProfile);
}

export function* athleteSagas() {
  yield all([
    call(onCreateProfileStart),
    call(onUpdateProfileStart),
    call(onFetchAthletesProfileStart),
    call(onFetchAthleteProfileStart),
    call(onFetchMyAthleteProfileStart)
  ]);
}
