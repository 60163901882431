import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PaletteIcon from '@material-ui/icons/Palette';
import ThemeContext from 'theme/ThemeContext';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { setStorage } from 'utils/localStorage';

const themes = ['basil', 'crane', 'material', 'materialDark', 'pinky', 'rally', 'reply'];

export default function ThemeToggler() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { theme, setTheme } = React.useContext(ThemeContext);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onChangeTheme = (theme) => {
    setTheme(theme);
    setStorage('theme', theme);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <PaletteIcon />
        </ListItemIcon>
        <ListItemText primary="Change Theme" />
      </ListItem>
      <Menu id="theme-toggler" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => onChangeTheme(theme)}>
        {themes.map((option) => (
          <MenuItem key={option} selected={option === theme} onClick={() => onChangeTheme(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}

ThemeToggler.propTypes = {
  className: PropTypes.string
};
