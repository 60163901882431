import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { logoutStart } from 'store/user/user_actions';
import { Toolbar, IconButton, AppBar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

// Component styles
import useStyles from './styles';
import UserMenu from '../UserMenu/UserMenu';

function Topbar(props) {
  const dispatch = useDispatch();
  const { className, children, openSidebar, onToggleSidebar } = props;
  const classes = useStyles(props);
  const handleLogout = () => dispatch(logoutStart());

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.brandWrapper}>
          <div className={classes.logo}>Santa Admin</div>
          <IconButton className={classes.menuButton} aria-label="Menu" onClick={onToggleSidebar}>
            {openSidebar ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <div className={classes.userSettings}>
          <UserMenu handleLogout={handleLogout} />
        </div>
      </Toolbar>
      {children}
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
  onToggleSidebar: PropTypes.func,
  openSidebar: PropTypes.bool,
  children: PropTypes.node
};

export default Topbar;
