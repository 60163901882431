import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './styles';

const RaceCard = ({ race, onEdit, onDelete }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          action={
            <Fragment>
              <IconButton aria-label="settings" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu id="fade-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    onEdit();
                    handleClose();
                  }}
                >
                  <EditIcon className={classes.icons} color="primary" />
                  <Typography variant="inherit">Edit</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onDelete();
                    handleClose();
                  }}
                >
                  <DeleteIcon className={classes.icons} color="error" />
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
              </Menu>
            </Fragment>
          }
          title={race.name}
          subheader={new Date(race.startDate).toLocaleDateString()}
        />

        <CardContent className={classes.cardContent}>
          <Typography variant="body2" color="textSecondary" component="p">
            {race.description}
          </Typography>
        </CardContent>

        <CardActions className={classes.actions} disableSpacing>
          <Button fullWidth color="primary" variant="contained" component={Link} to={`/admin/race-results/${race._id}/`}>
            Results
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

RaceCard.propTypes = { race: PropTypes.object, onEdit: PropTypes.func, onDelete: PropTypes.func };

export default RaceCard;
