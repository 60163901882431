import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  section: {
    backgroundColor: '#f7f7f7',
    padding: theme.spacing(10, 0)
  },
  paper: {
    maxWidth: 800,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3)
  },
  button: {
    color: '#fff',
    padding: theme.spacing(2, 5),
    textTransform: 'capitalize',
    backgroundColor: 'rgb(80, 128, 60)',
    '&:hover': {
      backgroundColor: 'rgb(255, 152, 52)'
    }
  },
  headingTertiary: {
    color: '#777',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(5)
  },
  paragraph: {
    fontSize: theme.spacing(2),
    maxWidth: 750,
    margin: 'auto',
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    button: {
      padding: theme.spacing(1, 3)
    },
    paper: {
      flexDirection: 'column'
    },
    title: {
      margin: theme.spacing(2, 0)
    }
  }
}));
