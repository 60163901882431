import React from 'react';
import Header from './components/Header/Header';
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import useStyles from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAuthenticatedUser } from 'store/user/user_selectors';
import { logoutStart } from 'store/user/user_actions';

const structuredSelector = createStructuredSelector({
  isAuthenticated: getAuthenticatedUser
});

const PublicLayout = ({ children, fullScreenHeader }) => {
  const { isAuthenticated } = useSelector(structuredSelector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleLogout = () => dispatch(logoutStart());
  return (
    <div className={classes.root}>
      <Navigation isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
      <Header fullScreen={fullScreenHeader} />
      {children}
      <Footer />
    </div>
  );
};
export default PublicLayout;
