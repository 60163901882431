import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },

  [theme.breakpoints.down('sm')]: {
    root: {
      padding: 0
    }
  }
}));
