import { v4 } from 'uuid';
import { delay, put, all, takeEvery, call } from 'redux-saga/effects';
import alertTypes from './alert_types';
import * as alertActions from './alert_actions';

export function* setAlertAsync({ payload }) {
  const id = v4();
  const { msg, alertType, timeout } = payload;
  try {
    yield put(alertActions.setAlert(msg, alertType, id));
    yield delay(timeout);
    yield put(alertActions.removeAlert(id));
  } catch (error) {
    console.log(error);
  }
}

export function* setAlertStart() {
  yield takeEvery(alertTypes.ON_SET_ALERT, setAlertAsync);
}

export function* alertSagas() {
  yield all([call(setAlertStart)]);
}
