import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { stableSort, getSorting } from 'utils/tablesSorting';
import ResultsTableHead from './components/ResultsTableHead/RaceResultsTableHead';
import useStyles from './styles';

const ResultsTable = ({ results, onSelectResult }) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('generalRanking');
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, results.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <TableContainer>
          <Table className={classes.table} aria-label="race-results table">
            <ResultsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={results.length} />
            <TableBody>
              {stableSort(results, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover key={row._id} onClick={() => onSelectResult(row)}>
                      <TableCell>{row.generalRanking}</TableCell>
                      <TableCell>{row.sexRanking}</TableCell>
                      <TableCell>{row.ageRanking}</TableCell>
                      <TableCell>{row.points}</TableCell>
                      <TableCell>{row.fullname}</TableCell>
                      <TableCell>{row.ageCategory}</TableCell>
                      <TableCell>{row.group}</TableCell>
                      <TableCell>{row.distance}</TableCell>
                      <TableCell>{row.totalTime}</TableCell>
                      <TableCell>{row.tempo}</TableCell>
                      <TableCell>{row.elevation}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={results.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

ResultsTable.propTypes = { results: PropTypes.array, onSelectResult: PropTypes.func };

export default ResultsTable;
