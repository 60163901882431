import { createSelector } from 'reselect';

const getClaimState = (state) => state.claim;
const getUserState = (state) => state.user;

export const getClaims = createSelector([getClaimState], (claim) => claim.claims);
export const getClaimByAthleteId = createSelector(
  getClaimState,
  (_, athleteId) => athleteId,
  ({ claims }, athleteId) => claims.find((claim) => claim.athleteId === athleteId)
);
export const getCurrentUserClaim = createSelector([getClaimState, getUserState], (claimState, userState) => {
  const { user } = userState;
  const { claims } = claimState;
  if (!user) return undefined;
  return claims.find((claim) => claim.user._id === user._id);
});
