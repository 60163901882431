import { takeLatest, put, all, call } from 'redux-saga/effects';
import teamTypes from './team_types';
import * as teamActions from './team_actions';
import { setAuthHeaders } from 'utils';
import { setAlertStart } from 'store/alert/alert_actions';

export function* fetchTeams() {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/teams`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();
    yield put(teamActions.fetchTeamsSuccess(responseData));
  } catch (error) {
    yield put(teamActions.fetchTeamsFailure(error));
    console.log(error);
  }
}

export function* addTeam({ payload: team }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/teams`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(team)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(teamActions.addTeamSuccess(responseData));
      yield put(setAlertStart('Team Created', 'success', 5000));
      yield put(teamActions.fetchTeamsStart());
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(setAlertStart('something went wrong', 'error', 5000));
    yield put(teamActions.addTeamFailure(error));
    console.log(error);
  }
}

export function* editTeam({ payload: team }) {
  const { _id, createdAt, updatedAt, __v, ...rest } = team;
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/teams/${_id}`;
    const response = yield fetch(url, {
      method: 'PATCH',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(rest)
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(teamActions.addTeamSuccess(responseData));
      yield put(setAlertStart('Team Updated', 'success', 5000));
      yield put(teamActions.fetchTeamsStart());
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(setAlertStart('something went wrong', 'error', 5000));
    yield put(teamActions.addTeamFailure(error));
    console.log(error);
  }
}

export function* deleteTeam({ payload: teamId }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/teams/${teamId}`;
    const response = yield fetch(url, {
      method: 'DELETE',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' })
    });
    if (response.ok) {
      yield put(setAlertStart('Team Deleted', 'success', 5000));
      yield put(teamActions.deleteTeamSuccess(teamId));
    }
  } catch (error) {
    yield put(teamActions.deleteTeamFailure(error));
    console.log(error);
  }
}

export function* onFetchTeamsStart() {
  yield takeLatest(teamTypes.FETCH_TEAMS_START, fetchTeams);
}

export function* onAddTeamStart() {
  yield takeLatest(teamTypes.ADD_TEAM_START, addTeam);
}

export function* onEditTeamStart() {
  yield takeLatest(teamTypes.EDIT_TEAM_START, editTeam);
}

export function* onDeleteTeamStart() {
  yield takeLatest(teamTypes.DELETE_TEAM_START, deleteTeam);
}

export function* teamSagas() {
  yield all([call(onFetchTeamsStart), call(onAddTeamStart), call(onEditTeamStart), call(onDeleteTeamStart)]);
}
