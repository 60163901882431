import claimTypes from './claim_types';

const INITIAL_STATE = {
  claims: [],
  loading: false,
  error: null
};

const claimReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case claimTypes.FETCH_CLAIMS_SUCCESS:
      return {
        ...state,
        claims: payload,
        loading: false,
        error: null
      };
    case claimTypes.CLAIMS_FAILURE:
      return { ...INITIAL_STATE, error: payload, loading: false };
    default:
      return state;
  }
};

export default claimReducer;
