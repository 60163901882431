import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Register from 'pages/public/Register/Register';
import Login from 'pages/public/Login/Login';
import Home from 'pages/public/Home/Home';

import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import MainLayout from 'layouts/Main/Main';
import PublicLayout from 'layouts/Public/Public';
import Athletes from 'pages/admin/Athletes/Athletes';
import Profile from 'pages/admin/Profile/Profile';
import Races from 'pages/admin/Races/Races';
import RaceResults from 'pages/admin/RaceResults/RaceResults';
import WithLayoutRoute from './components/WithLayoutRoute/WithLayoutRoute';
import ResultsPage from 'pages/public/ResultsPage/ResultsPage';
import Events from 'pages/admin/Events/Events';
import EventPage from 'pages/public/EventPage/EventPage';
import Teams from 'pages/admin/Teams/Teams';
import UserProfile from 'pages/public/UserProfile/UserProfile';
import Claims from 'pages/admin/Claims/Claims';

function Routes() {
  return (
    <Switch>
      <WithLayoutRoute layout={PublicLayout} layoutProps={{ fullScreenHeader: true }} exact component={Home} path="/" />
      <WithLayoutRoute layout={PublicLayout} exact component={ResultsPage} path="/race-results/:raceId" />
      <WithLayoutRoute layout={PublicLayout} exact component={EventPage} path="/event/:eventId" />
      <WithLayoutRoute layout={PublicLayout} exact component={UserProfile} path="/profile" />
      <Route exact component={Login} path="/login" />
      <Route exact component={Register} path="/register" />
      <ProtectedRoute component={Athletes} exact layout={MainLayout} path="/admin/athletes" />
      <ProtectedRoute component={Profile} exact layout={MainLayout} path="/admin/profile" />
      <ProtectedRoute component={Races} exact layout={MainLayout} path="/admin/races" />
      <ProtectedRoute component={Events} exact layout={MainLayout} path="/admin/events" />
      <ProtectedRoute component={Teams} exact layout={MainLayout} path="/admin/teams" />
      <ProtectedRoute component={Claims} exact layout={MainLayout} path="/admin/claims" />
      <ProtectedRoute component={RaceResults} exact layout={MainLayout} path="/admin/race-results/:raceId" />
      <Redirect to="/admin/races" />
    </Switch>
  );
}

export default Routes;
