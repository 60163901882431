import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  header: {
    height: ({ fullScreen }) => (fullScreen ? '80vh' : '25vh'),
    marginBottom: ({ fullScreen }) => (fullScreen ? 0 : 30),
    position: 'relative',
    backgroundImage:
      'linear-gradient(to right bottom, rgba(0, 0, 0, 0.5), rgba(80, 128, 60, 0.6)),url(/images/6.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    clipPath: 'polygon(0 0, 100% 0, 100% 70vh, 0 100%)'
  },
  logoContainer: {
    position: 'absolute',
    top: theme.spacing(7),
    left: theme.spacing(7)
  },
  logo: {
    height: theme.spacing(10)
  },
  textContainer: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: ' translate(-50%, -50%)',
    textAlign: 'center'
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: 0
    },
    logoContainer: {
      top: theme.spacing(7),
      left: theme.spacing(7)
    },
    logo: {
      height: theme.spacing(7)
    }
  }
}));
