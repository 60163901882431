import teamTypes from './team_types';

export const fetchTeamsStart = () => ({
  type: teamTypes.FETCH_TEAMS_START
});

export const fetchTeamsSuccess = (teams) => ({
  type: teamTypes.FETCH_TEAMS_SUCCESS,
  payload: teams
});

export const fetchTeamsFailure = (error) => ({
  type: teamTypes.FETCH_TEAMS_FAILURE,
  payload: error
});

export const addTeamStart = (team) => ({
  type: teamTypes.ADD_TEAM_START,
  payload: team
});

export const addTeamSuccess = (team) => ({
  type: teamTypes.ADD_TEAM_SUCCESS,
  payload: team
});

export const addTeamFailure = (error) => ({
  type: teamTypes.ADD_TEAM_FAILURE,
  payload: error
});

export const editTeamStart = (team) => ({
  type: teamTypes.EDIT_TEAM_START,
  payload: team
});

export const editTeamSuccess = (team) => ({
  type: teamTypes.EDIT_TEAM_SUCCESS,
  payload: team
});

export const editTeamFailure = (error) => ({
  type: teamTypes.EDIT_TEAM_FAILURE,
  payload: error
});

export const deleteTeamStart = (teamId) => ({
  type: teamTypes.DELETE_TEAM_START,
  payload: teamId
});

export const deleteTeamSuccess = (team) => ({
  type: teamTypes.DELETE_TEAM_SUCCESS,
  payload: team
});

export const deleteTeamFailure = (error) => ({
  type: teamTypes.DELETE_TEAM_FAILURE,
  payload: error
});
