import { Button, Container, Grid, MenuItem, Paper, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Loader } from 'components';
import ColoredHeading from 'components/ColoredHeading/ColoredHeading';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRacesStart } from 'store/race/race_actions';
import { getRaceById } from 'store/race/race_selectors';
import { setAuthHeaders } from 'utils';
import { calculateRanking } from 'utils/pacecalculator';
import { search_match } from 'utils/search';
import ResultsTable from '../components/ResultsTable/ResultsTable';
import UserResultDialog from '../components/UserResultDialog/UserResultDialog';
import CollapsibleTable from './CollapsibleTable';

const ResultsPage = ({ match }) => {
  const { raceId } = match.params;
  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedRace = useSelector(getRaceById(raceId));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
  const [selectedSexGroup, setSelectedSexGroup] = useState('');
  const [selectedResult, setSelectedResult] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleReset = () => {
    setSearchTerm('');
    setSelectedGroup('');
    setSelectedAgeGroup('');
    setSelectedSexGroup('');
  };

  const handleChangeGroup = (value) => {
    if (value) {
      setSelectedGroup(value.name);
    } else {
      setSelectedGroup('');
    }
  };

  useEffect(() => {
    dispatch(fetchRacesStart());
  }, [dispatch]);

  const fetchGroups = async () => {
    try {
      const url = `${process.env.REACT_APP_API_PROXY}/api/groups`;
      const response = await fetch(url, {
        method: 'GET',
        headers: setAuthHeaders()
      });
      const responseData = await response.json();
      setGroups(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const withCalculatedRanking = selectedRace ? calculateRanking(selectedRace.results) : [];

  const filterByAgeGroup = selectedAgeGroup
    ? withCalculatedRanking.filter((result) => result.ageCategory === selectedAgeGroup)
    : withCalculatedRanking;

  const filterBySex = selectedSexGroup
    ? filterByAgeGroup.filter((result) => result.sexCategory === selectedSexGroup)
    : filterByAgeGroup;

  const filterByTeam = selectedGroup ? filterBySex.filter((result) => result.group === selectedGroup) : filterBySex;

  const withFilters = filterByTeam.map((item) => ({
    ...item,
    fullname: `${item.firstname} ${item.lastname}`
  }));

  const filteredRaceResults = search_match(searchTerm, withFilters, 'fullname');

  const onSelectResult = (result) => {
    setOpenDialog(!openDialog);
    setSelectedResult(result);
  };

  return selectedRace ? (
    <Container maxWidth="xl">
      <ColoredHeading marginTop={5}>{selectedRace.name}</ColoredHeading>
      <Paper style={{ padding: 20, marginBottom: 20, display: 'flex' }}>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Αναζήτηση με Επώνυμο"
              name="searchTerm"
              value={searchTerm}
              onChange={({ target: { value } }) => setSearchTerm(value)}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              select
              label="Ηλικιακή Κατηγορία"
              name="ageCategory"
              value={selectedAgeGroup}
              onChange={({ target: { value } }) => setSelectedAgeGroup(value)}
            >
              {['N/A', 'M', 'M30', 'M40', 'M50', 'M60', 'F', 'F30', 'F40', 'F50', 'F60'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              select
              label="Φύλλο"
              name="sexCategory"
              value={selectedSexGroup}
              onChange={({ target: { value } }) => setSelectedSexGroup(value)}
            >
              {[
                { value: 'M', text: 'ΑΝΔΡΕΣ' },
                { value: 'F', text: 'ΓΥΝΑΙΚΕΣ' }
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={3}>
            {groups.length && (
              <Autocomplete
                options={groups}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => handleChangeGroup(value)}
                renderInput={(params) => <TextField {...params} label="Group" />}
              />
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            <Button fullWidth variant="contained" onClick={handleReset}>
              Reset
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {isDesktop ? (
        <ResultsTable results={filteredRaceResults} onSelectResult={onSelectResult} />
      ) : (
        <CollapsibleTable results={filteredRaceResults} onSelectResult={onSelectResult} />
      )}
      <UserResultDialog isDesktop={isDesktop} open={openDialog} handleClose={() => setOpenDialog(false)} data={selectedResult} />
    </Container>
  ) : (
    <Loader />
  );
};

ResultsPage.propTypes = { match: PropTypes.object };

export default ResultsPage;
