import alertTypes from './alert_types';

export const setAlertStart = (msg, alertType, timeout = 5000) => ({
  type: alertTypes.ON_SET_ALERT,
  payload: { msg, alertType, timeout }
});

export const setAlert = (msg, alertType, id) => ({
  type: alertTypes.SET_ALERT,
  payload: { msg, alertType, id }
});

export const removeAlert = (id) => ({
  type: alertTypes.REMOVE_ALERT,
  payload: id
});
