const racesTypes = {
  FETCH_RACES_START: 'FETCH_RACES_START',
  FETCH_RACES_SUCCESS: 'FETCH_RACES_SUCCESS',
  FETCH_RACES_FAILURE: 'FETCH_RACES_FAILURE',

  ADD_RACE_START: 'ADD_RACE_START',
  ADD_RACE_SUCCESS: 'ADD_RACE_SUCCESS',
  ADD_RACE_FAILURE: 'ADD_RACE_FAILURE',

  EDIT_RACE_START: 'EDIT_RACE_START',
  EDIT_RACE_SUCCESS: 'EDIT_RACE_SUCCESS',
  EDIT_RACE_FAILURE: 'EDIT_RACE_FAILURE',

  DELETE_RACE_START: 'DELETE_RACE_START',
  DELETE_RACE_SUCCESS: 'DELETE_RACE_SUCCESS',
  DELETE_RACE_FAILURE: 'DELETE_RACE_FAILURE',

  REGISTER_USER_ON_RACE_START: 'REGISTER_USER_ON_RACE_START',
  REGISTER_USER_ON_RACE_SUCCESS: 'REGISTER_USER_ON_RACE_SUCCESS',
  REGISTER_USER_ON_RACE_FAILURE: 'REGISTER_USER_ON_RACE_FAILURE',

  ADD_RACE_RESULT_START: 'ADD_RACE_RESULT_START',
  ADD_RACE_RESULT_SUCCESS: 'ADD_RACE_RESULT_SUCCESS',
  ADD_RACE_RESULT_FAILURE: 'ADD_RACE_RESULT_FAILURE',

  EDIT_RACE_RESULT_START: 'EDIT_RACE_RESULT_START',
  EDIT_RACE_RESULT_SUCCESS: 'EDIT_RACE_RESULT_SUCCESS',
  EDIT_RACE_RESULT_FAILURE: 'EDIT_RACE_RESULT_FAILURE',

  DELETE_RACE_RESULTS_START: 'DELETE_RACE_RESULTS_START',
  DELETE_RACE_RESULTS_SUCCESS: 'DELETE_RACE_RESULTS_SUCCESS',
  DELETE_RACE_RESULTS_FAILURE: 'DELETE_RACE_RESULTS_FAILURE',

  FETCH_RACE_RESULTS_BY_ID_START: 'FETCH_RACE_RESULTS_BY_ID_START',
  FETCH_RACE_RESULTS_BY_ID_SUCCESS: 'FETCH_RACE_RESULTS_BY_ID_SUCCESS',
  FETCH_RACE_RESULTS_BY_ID_FAILURE: 'FETCH_RACE_RESULTS_BY_ID_FAILURE'
};

export default racesTypes;
