import { takeLatest, put, all, call } from 'redux-saga/effects';
import claimTypes from './claim_types';
import * as claimActions from './claim_actions';
import { setAlertStart } from 'store/alert/alert_actions';
import { setAuthHeaders } from 'utils';

export function* fetchClaims() {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/claims`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();

    if (response.ok) {
      yield put(claimActions.fetchClaimsSuccess(responseData));
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(claimActions.claimsFailure(error));
  }
}

export function* createClaim({ payload }) {
  try {
    const { userId, athleteId } = payload;
    const url = `${process.env.REACT_APP_API_PROXY}/api/claims`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: setAuthHeaders({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({ userId, athleteId })
    });
    const responseData = yield response.json();
    if (response.ok) {
      yield put(claimActions.createClaimsSuccess(responseData));
      yield put(setAlertStart('Athlete Claimed', 'success', 10000));
      fetchClaims();
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(claimActions.claimsFailure(error));
  }
}

export function* saveClaim({ payload }) {
  const { athleteId, userId, claimId } = payload;
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/profile/${athleteId}/${userId}/claim/${claimId}`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();

    if (response.ok) {
      console.log(responseData);
    } else {
      yield put(setAlertStart(responseData.message, 'error', 10000));
    }
  } catch (error) {
    yield put(claimActions.claimsFailure(error));
  }
}

export function* onFetchClaimsStart() {
  yield takeLatest(claimTypes.FETCH_CLAIMS_START, fetchClaims);
}

export function* onCreateClaimStart() {
  yield takeLatest(claimTypes.CREATE_CLAIM_START, createClaim);
}

export function* onSaveClaimsStart() {
  yield takeLatest(claimTypes.SAVE_CLAIM_START, saveClaim);
}

export function* claimSagas() {
  yield all([call(onFetchClaimsStart), call(onSaveClaimsStart), call(onCreateClaimStart)]);
}
