import { makeStyles } from '@material-ui/core';
export default makeStyles((theme) => ({
  paper: { padding: theme.spacing(3) },
  header: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
  flex: { display: 'flex', alignItems: 'center', justifyContent: 'flex-start' },
  title: { paddingRight: theme.spacing(2), fontSize: '1.2rem', fontWeight: 800 },
  seperator: { display: 'block', width: 1, height: 22, background: '#e7e8ef' },
  details: { paddingLeft: theme.spacing(2), color: '#959cb6', fontSize: '1rem', fontWeight: 400 },
  button: { padding: theme.spacing(1, 4) }
}));
