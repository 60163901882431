import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  tableHead: { background: 'rgb(80, 128, 60)' },
  tableCell: {
    color: '#fff'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));
