import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, TextField, MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { addTeamStart, editTeamStart } from 'store/team/team_actions';
import useStyles from './styles';

const INITIAL_FORM = {
  name: '',
  description: '',
  private: false,
  members: []
};

const AddTeam = ({ editedTeam }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { teams } = useSelector(structuredSelector);
  const [form, setForm] = useState(INITIAL_FORM);

  const handleChange = (team) => {
    const { name, value } = team.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    editedTeam ? dispatch(editTeamStart(form)) : dispatch(addTeamStart(form));
  };

  useEffect(() => {
    if (editedTeam) {
      setForm({ ...editedTeam });
    }
  }, [editedTeam]);

  return (
    <Grid className={classes.root} container spacing={3} alignItems="flex-end">
      <Grid item xs={12}>
        <Typography className={classes.modeTitle} variant="h2">
          {editedTeam ? 'Edit ' : 'Add New '} Team
        </Typography>
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          variant="outlined"
          label="Team Name"
          name="name"
          value={form.name}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          variant="outlined"
          label="Team Description"
          name="description"
          value={form.description}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          label="Is Team Private?"
          name="private"
          select
          value={form.private}
          variant="outlined"
          onChange={handleChange}
        >
          {[
            { text: 'yes', value: true },
            { text: 'no', value: false }
          ].map((option) => (
            <MenuItem key={option.text} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          label="Members"
          name="members"
          select
          value={form.members}
          variant="outlined"
          onChange={handleChange}
        >
          {[''].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} md={3}>
        <Button fullWidth className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
          {editedTeam ? 'Edit ' : 'Add New '} Team
        </Button>
      </Grid>
    </Grid>
  );
};

AddTeam.propTypes = { editedTeam: PropTypes.object };

export default AddTeam;
