import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Grid, Paper, Typography, Button } from '@material-ui/core';
import ColoredHeading from 'components/ColoredHeading/ColoredHeading';
import useStyles from './styles';

const RaceSection = ({ races, description = '' }) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <ColoredHeading>Αποτελεσματα Αγωνων</ColoredHeading>
      <Container>
        <Typography variant="body1" className={classes.paragraph}>
          {description}
        </Typography>
        <Typography className={classes.headingTertiary} variant="h3" align="center">
          Λιστα Αγωνων
        </Typography>
        <Grid container spacing={5}>
          {races.length ? (
            races.map((race) => (
              <Grid key={race._id} item xs={12}>
                <Paper className={classes.paper}>
                  <Typography className={classes.title}>{race.name}</Typography>
                  <Button className={classes.button} variant="contained" component={Link} to={`/race-results/${race._id}`}>
                    Αποτελέσματα
                  </Button>
                </Paper>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography align="center">Δεν βρέθηκαν αγώνες...</Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

RaceSection.propTypes = { races: PropTypes.array, description: PropTypes.string };

export default RaceSection;
