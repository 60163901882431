const athleteTypes = {
  CREATE_ATHLETE_PROFILE_START: 'CREATE_ATHLETE_PROFILE_START',
  CREATE_ATHLETE_PROFILE_SUCCESS: 'CREATE_ATHLETE_PROFILE_SUCCESS',
  UPDATE_ATHLETE_PROFILE_START: 'UPDATE_ATHLETE_PROFILE_START',
  UPDATE_ATHLETE_PROFILE_SUCCESS: 'UPDATE_ATHLETE_PROFILE_SUCCESS',

  FETCH_ATHLETES_PROFILE_START: 'FETCH_ATHLETES_PROFILE_START',
  FETCH_ATHLETES_PROFILE_SUCCESS: 'FETCH_ATHLETES_PROFILE_SUCCESS',

  FETCH_ATHLETE_PROFILE_START: 'FETCH_ATHLETE_PROFILE_START',
  FETCH_MY_ATHLETE_PROFILE_START: 'FETCH_MY_ATHLETE_PROFILE_START',
  FETCH_ATHLETE_PROFILE_SUCCESS: 'FETCH_ATHLETE_PROFILE_SUCCESS',

  ATHLETES_PROFILE_FAILURE: 'ATHLETES_PROFILE_FAILURE'
};

export default athleteTypes;
