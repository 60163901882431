import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addEventStart, editEventStart } from 'store/event/event_actions';
import { getRaces } from 'store/race/race_selectors';
import { Grid, Button, Typography, TextField, MenuItem } from '@material-ui/core';
import useStyles from './styles';

const INITIAL_FORM = {
  name: '',
  description: '',
  image: '',
  startDate: '',
  endDate: '',
  websiteUrl: '',
  isVirtual: false,
  isPublished: false,
  city: '',
  state: '',
  country: '',
  races: []
};

const AddEvent = ({ editedEvent }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const races = useSelector(getRaces);
  const [form, setForm] = useState(INITIAL_FORM);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    editedEvent ? dispatch(editEventStart(form)) : dispatch(addEventStart(form));
  };

  useEffect(() => {
    if (editedEvent) {
      const races = editedEvent.races.map((race) => race._id);
      setForm({ ...editedEvent, races });
    }
  }, [editedEvent]);

  return (
    <Grid className={classes.root} container spacing={3} alignItems="flex-end">
      <Grid item xs={12}>
        <Typography className={classes.modeTitle} variant="h2">
          {editedEvent ? 'Edit ' : 'Add New '} Event
        </Typography>
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          variant="outlined"
          label="Event Name"
          name="name"
          value={form.name}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          variant="outlined"
          label="Event Description"
          name="description"
          value={form.description}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField fullWidth variant="outlined" label="Event Image Url" name="image" value={form.image} onChange={handleChange} />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          variant="outlined"
          label="Event Website"
          name="websiteUrl"
          value={form.websiteUrl}
          onChange={handleChange}
          required
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          label="Is Event Virtual?"
          name="isVirtual"
          select
          value={form.isVirtual}
          variant="outlined"
          onChange={handleChange}
        >
          {[
            { text: 'yes', value: true },
            { text: 'no', value: false }
          ].map((option) => (
            <MenuItem key={option.text} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          label="Publish Event"
          name="isPublished"
          select
          value={form.isPublished}
          variant="outlined"
          onChange={handleChange}
        >
          {[
            { text: 'yes', value: true },
            { text: 'no', value: false }
          ].map((option) => (
            <MenuItem key={option.text} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          type="date"
          label="Start Date"
          name="startDate"
          value={form.startDate}
          variant="outlined"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true
          }}
          required
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          type="date"
          label="End Date"
          name="endDate"
          value={form.endDate}
          variant="outlined"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true
          }}
          required
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField fullWidth label="City" name="city" value={form.city} variant="outlined" onChange={handleChange} />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth label="State" name="state" value={form.state} variant="outlined" onChange={handleChange} />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth label="Country" name="country" value={form.country} variant="outlined" onChange={handleChange} />
      </Grid>

      {races && (
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            label="Races"
            name="races"
            select
            value={form.races}
            variant="outlined"
            onChange={handleChange}
            SelectProps={{
              multiple: true
            }}
          >
            {races.map((race) => (
              <MenuItem key={race._id} value={race._id}>
                {race.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}

      <Grid item xs={12} md={3}>
        <Button fullWidth className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
          {editedEvent ? 'Edit ' : 'Add New '} Event
        </Button>
      </Grid>
    </Grid>
  );
};

AddEvent.propTypes = { editedEvent: PropTypes.object };

export default AddEvent;
