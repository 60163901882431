import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Menu, MenuItem } from '@material-ui/core';
import { trimString } from 'utils/strings';
import useStyles from './styles';

const TeamCard = ({ team, onEditTeam, onDeleteTeam }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (team) => setAnchorEl(team.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          action={
            <Fragment>
              <IconButton aria-label="settings" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu id="fade-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    onEditTeam();
                    handleClose();
                  }}
                >
                  <EditIcon className={classes.icons} color="primary" />
                  <Typography variant="inherit">Edit</Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    onDeleteTeam();
                    handleClose();
                  }}
                >
                  <DeleteIcon className={classes.icons} color="error" />
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
              </Menu>
            </Fragment>
          }
          title={team.name}
          subheader={trimString(team.description, 150)}
        />
      </Card>
    </>
  );
};

TeamCard.propTypes = { team: PropTypes.object, onEditTeam: PropTypes.func, onDeleteTeam: PropTypes.func };

export default TeamCard;
