import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(4)
  }
}));
