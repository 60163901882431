const teamTypes = {
  FETCH_TEAMS_START: 'FETCH_TEAMS_START',
  FETCH_TEAMS_SUCCESS: 'FETCH_TEAMS_SUCCESS',
  FETCH_TEAMS_FAILURE: 'FETCH_TEAMS_FAILURE',

  ADD_TEAM_START: 'ADD_TEAM_START',
  ADD_TEAM_SUCCESS: 'ADD_TEAM_SUCCESS',
  ADD_TEAM_FAILURE: 'ADD_TEAM_FAILURE',

  EDIT_TEAM_START: 'EDIT_TEAM_START',
  EDIT_TEAM_SUCCESS: 'EDIT_TEAM_SUCCESS',
  EDIT_TEAM_FAILURE: 'EDIT_TEAM_FAILURE',

  DELETE_TEAM_START: 'DELETE_TEAM_START',
  DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
  DELETE_TEAM_FAILURE: 'DELETE_TEAM_FAILURE'
};

export default teamTypes;
