import { CircularProgress } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={100} classes={{ colorPrimary: classes.colorPrimary }} />
    </div>
  );
};

export default Loader;
