import { getStorage, setStorage, removeStorage } from './localStorage';

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () => (isBrowser() && getStorage('user') ? JSON.parse(getStorage('user')) : null);
export const setUser = (user) => setStorage('user', JSON.stringify(user));
export const removeUser = () => removeStorage('user');

// jwtToken
export const getToken = () => isBrowser() && getStorage('jwtToken');
export const setToken = (token) => setStorage('jwtToken', token);
export const removeToken = () => removeStorage('jwtToken');

export const setAuthHeaders = (headersObject) => {
  let headers = { ...headersObject };
  const token = getToken();

  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`
    };
  }
  return headers;
};
