import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CardActionArea } from '@material-ui/core';
import useStyles from './styles';

const truncate = (str, max = 10) => {
  const array = str.trim().split(' ');
  const ellipsis = array.length > max ? '...' : '';
  return array.slice(0, max).join(' ') + ellipsis;
};

const EventSimpleCard = ({ event }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader title={event.name} subheader={new Date(event.startDate).toLocaleDateString()} />
      <CardActionArea component={Link} to={`/event/${event._id}/`}>
        <CardMedia className={classes.media} image={event.image || '/images/santarunningbanner.jpg'} title="Running Race" />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {truncate(event.description, 25)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

EventSimpleCard.propTypes = { event: PropTypes.object };

export default EventSimpleCard;
