import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClaimsStart, saveClaimStart } from 'store/claim/claim_actions';
import { getClaims } from 'store/claim/claim_selectors';
import { fetchAthletesProfileStart } from 'store/athlete/athlete_actions';
import useStyles from './styles';

const headCells = [
  { id: 'athleteId', label: 'AthleteId' },
  { id: 'username', label: 'Username' },
  { id: 'email', label: 'Email' },
  { id: 'Status', label: 'Status' },
  { id: 'createdAt', label: 'createdAt' },
  { id: 'action', label: 'action' }
];

export default function Claims() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const claims = useSelector(getClaims);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, claims.length - page * rowsPerPage);

  useEffect(() => {
    dispatch(fetchClaimsStart());
    dispatch(fetchAthletesProfileStart());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {claims.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                const {
                  user: { _id, username, email }
                } = row;

                return (
                  <TableRow tabIndex={-1} key={row._id}>
                    <TableCell>{row.athleteId}</TableCell>
                    <TableCell>{username}</TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => dispatch(saveClaimStart(row.athleteId, _id, row._id))}
                      >
                        {row.status === 'completed' ? 'Disconnect' : 'Approve'}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={claims.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
