export const stripZeros = (inputNumber) => {
  const n = parseFloat(inputNumber);
  return !isNaN(n) ? n : 0;
};

export const isPositiveNumber = (inputNumber) => {
  const n = parseFloat(inputNumber);
  return !isNaN(n) && n >= 0;
};

export const seconds_to_hhmmss = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds / 60) - h * 60;
  const s = seconds - m * 60 - h * 3600;

  return {
    h: ('0' + h).slice(-2),
    m: ('0' + m).slice(-2),
    s: ('0' + s).slice(-2)
  };
};

export const hhmmss_to_seconds = (hours, minutes, seconds) => {
  return parseFloat(hours * 3600) + parseFloat(minutes * 60) + parseFloat(seconds);
};

export const calculatePace = (time, distance) => {
  const pace_sec = time / distance;
  const pace = seconds_to_hhmmss(pace_sec.toFixed());
  return pace;
};
export const calculateDistance = (time, pace) => time / pace;
export const calculateTime = (distance, pace) => distance * pace;

export const convert_units = (input_unit, output_unit) => {
  const conversion_map = {
    Mile: {
      Mile: 1,
      Kilometer: 1.609344,
      Meter: 1609.344,
      Yard: 1760,
      'Half Mile': 2,
      'Quarter Mile': 4,
      'Eigth Mile': 8,
      '1500M': 1.072896,
      '800M': 2.01168,
      '400M': 4.02336,
      '200M': 8.04672
    },
    Kilometer: {
      Mile: 0.6213712,
      Kilometer: 1,
      Meter: 1000,
      Yard: 1093.613,
      'Half Mile': 1.2427424,
      'Quarter Mile': 2.4854848,
      'Eigth Mile': 4.9709696,
      '1500M': 0.66666666,
      '800M': 1.25,
      '400M': 2.5,
      '200M': 5
    },
    Meter: {
      Mile: 0.0006213712,
      Kilometer: 0.001,
      Meter: 1,
      Yard: 1.093613,
      'Half Mile': 0.0012427424,
      'Quarter Mile': 0.0024854848,
      'Eigth Mile': 0.0049709696,
      '1500M': 0.00066666666,
      '800M': 0.00125,
      '400M': 0.0025,
      '200M': 0.005
    },
    Yard: {
      Mile: 0.0005681,
      Kilometer: 0.0009144,
      Meter: 0.9144,
      Yard: 1,
      'Half Mile': 0.0011362,
      'Quarter Mile': 0.0022724,
      'Eigth Mile': 0.0045448,
      '1500M': 0.0006096,
      '800M': 0.001143,
      '400M': 0.002286,
      '200M': 0.004572
    }
  };
  return conversion_map[input_unit][output_unit];
};

export const calculateRanking = (results) => {
  // group by
  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item]
      }),
      {}
    );
  // sort by time
  const sortedByTime = (items) =>
    items.sort((a, b) => {
      const a_sec_tempo = hhmmss_to_seconds(0, ...a.tempo.split(':'));
      const b_sec_tempo = hhmmss_to_seconds(0, ...b.tempo.split(':'));
      const a_total_time = hhmmss_to_seconds(...a.totalTime.split(':'));
      const b_total_time = hhmmss_to_seconds(...b.totalTime.split(':'));
      return a_sec_tempo !== b_sec_tempo ? a_sec_tempo - b_sec_tempo : a_total_time - b_total_time;
    });

  let withAgeGroupCalculated = [];
  const resultsByAge = groupBy(results, 'ageCategory');
  // calculate ranking by group
  Object.keys(resultsByAge).forEach((ageGroup) => {
    const sorted = sortedByTime(resultsByAge[ageGroup]);
    const mapped = sorted.map((result, index) => ({ ...result, ageRanking: index + 1 }));
    withAgeGroupCalculated = [...withAgeGroupCalculated, ...mapped];
  });

  // filter by sex
  const maleSorted = sortedByTime(
    withAgeGroupCalculated.filter((result) => result.ageCategory.startsWith('M'))
  ).map((result, index) => ({
    ...result,
    sexRanking: index + 1,
    sexCategory: 'M'
  }));

  const femaleSorted = sortedByTime(
    withAgeGroupCalculated.filter((result) => result.ageCategory.startsWith('F'))
  ).map((result, index) => ({
    ...result,
    sexRanking: index + 1,
    sexCategory: 'F'
  }));

  const malesAndFemales = [...maleSorted, ...femaleSorted];

  // sort by time and map the general ranking
  const calculatedResults = sortedByTime(malesAndFemales).map((result, index) => ({
    ...result,
    generalRanking: index + 1
  }));

  return calculatedResults;
};
