import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  colorPrimary: { color: '#4f813a' }
}));
