import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ColoredHeading = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <h2 className={classes.heading}>{props.children}</h2>
    </div>
  );
};

ColoredHeading.propTypes = { children: PropTypes.any };

export default ColoredHeading;
