import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTeamByIds } from 'store/team/team_selectors';
import TeamCard from 'pages/admin/Teams/components/TeamCard/TeamCard';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { fetchTeamsStart, deleteTeamStart } from 'store/team/team_actions';
import AddTeam from './components/AddTeam/AddTeam';
import { fetchRacesStart } from 'store/race/race_actions';
import useStyles from './styles';

const structuredSelector = createStructuredSelector({
  teams: getTeamByIds
});

const Teams = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { teams } = useSelector(structuredSelector);
  const [showAddTeam, setShowAddTeam] = useState(false);
  const [editedTeam, setEditedTeam] = useState(null);

  useEffect(() => {
    dispatch(fetchTeamsStart());
    dispatch(fetchRacesStart());
  }, [dispatch]);

  const onAddTeam = () => {
    setShowAddTeam(!showAddTeam);
    setEditedTeam(null);
  };

  const onEditTeam = (id) => {
    const selectedTeam = teams && teams.find((team) => team._id === id);
    setEditedTeam(selectedTeam);
    setShowAddTeam(true);
  };

  const onDeleteTeam = (id) => {
    dispatch(deleteTeamStart(id));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.flex}>
              <Typography className={classes.title}>Teams</Typography>
              <span className={classes.seperator}></span>
              {teams && <Typography className={classes.details}>{teams.length} Total</Typography>}
            </div>
            <Button className={classes.button} onClick={onAddTeam}>
              {showAddTeam ? 'Close Team Form' : 'Add Team'}
            </Button>
          </div>
        </Paper>
      </Grid>
      {showAddTeam && (
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <AddTeam editedTeam={editedTeam} />
          </Paper>
        </Grid>
      )}
      {teams &&
        teams.map((team) => (
          <Grid key={team._id} item xs={12} md={6} lg={3}>
            <TeamCard team={team} onEditTeam={() => onEditTeam(team._id)} onDeleteTeam={() => onDeleteTeam(team._id)} />
          </Grid>
        ))}
    </Grid>
  );
};

export default Teams;
