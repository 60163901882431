import { takeLatest, put, all, call } from 'redux-saga/effects';
import userTypes from './user_types';
import * as userActions from './user_actions';
import { setAuthHeaders, setUser, removeUser } from 'utils';
import { getToken, getUser, setToken, removeToken } from 'utils/auth';
import { setAlertStart } from 'store/alert/alert_actions';

export function* login({ payload: { email, password } }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/auth/login`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    });
    const responseData = yield response.json();
    if (response.ok) {
      const { user, token } = responseData;
      user && setUser(user);
      token && setToken(token);
      yield put(setAlertStart(`Welcome ${user.username}`, 'success', 5000));
      yield put(userActions.loginSuccess(user, token));
    }
    if (responseData.error) {
      const { message } = responseData.error;
      removeUser();
      removeToken();
      yield put(setAlertStart(message, 'error', 5000));
      yield put(userActions.authFailure(message));
    }
  } catch (error) {
    removeUser();
    removeToken();
    yield put(setAlertStart('something went wrong.', 'error', 5000));
    yield put(userActions.authFailure(error));
  }
}

export function* facebookLogin({ payload }) {
  try {
    const { email, userID, name } = payload;
    const url = `${process.env.REACT_APP_API_PROXY}/api/auth/login/facebook`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, userID, name })
    });
    const responseData = yield response.json();
    if (response.ok) {
      const { user, token } = responseData;
      user && setUser(user);
      token && setToken(token);
      yield put(setAlertStart(`Welcome ${user.username}`, 'success', 5000));
      yield put(userActions.loginSuccess(user, token));
    }
    if (responseData.error) {
      removeUser();
      removeToken();
      yield put(setAlertStart(responseData.error.message, 'error', 5000));
      yield put(userActions.authFailure(responseData.error.message));
    }
  } catch (error) {
    removeUser();
    removeToken();
    yield put(setAlertStart('something went wrong.', 'error', 5000));
    yield put(userActions.authFailure(error));
  }
}

export function* googleLogin({ payload }) {
  try {
    const { email, googleId, name } = payload;
    const url = `${process.env.REACT_APP_API_PROXY}/api/auth/login/google`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, googleId, name })
    });
    const responseData = yield response.json();

    if (response.ok) {
      const { user, token } = responseData;
      user && setUser(user);
      token && setToken(token);
      yield put(setAlertStart(`Welcome ${user.username}`, 'success', 5000));
      yield put(userActions.loginSuccess(user, token));
    }
    if (responseData.error) {
      removeUser();
      removeToken();
      yield put(setAlertStart(responseData.error.message, 'error', 5000));
      yield put(userActions.authFailure(responseData.error.message));
    }
  } catch (error) {
    removeUser();
    removeToken();
    yield put(setAlertStart('something went wrong.', 'error', 5000));
    yield put(userActions.authFailure(error));
  }
}

export function* logout() {
  try {
    const token = getToken();
    const url = `${process.env.REACT_APP_API_PROXY}/api/auth/logout`;
    const response = yield fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    const responseData = yield response.json();

    if (response.ok) {
      removeUser();
      removeToken();
      yield put(setAlertStart(responseData.message, 'success', 5000));
      yield put(userActions.logoutSuccess());
    }
  } catch (error) {
    removeUser();
    removeToken();
    yield put(setAlertStart('something went wrong.', 'error', 5000));
    yield put(userActions.authFailure(error));
  }
}

export function* register({ payload: { email, password, username } }) {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/users`;
    const body = { email, password, username };
    const response = yield fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });

    const responseData = yield response.json();

    if (response.ok) {
      const { user, token } = responseData;
      user && setUser(user);
      token && setToken(token);
      yield put(userActions.registerSuccess(user));
      yield put(setAlertStart('Register Success.', 'success', 5000));
    } else {
      yield put(setAlertStart('something went wrong.', 'error', 5000));
    }
  } catch (error) {
    removeUser();
    removeToken();
    yield put(setAlertStart('something went wrong.', 'error', 5000));
    yield put(userActions.authFailure(error));
  }
}

export function* isUserAuthenticated() {
  const token = getToken();
  const loggedInUser = getUser();
  if (!token) return;
  if (loggedInUser && token) {
    return yield put(userActions.loginSuccess(loggedInUser, token));
  }
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/users/me`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();
    if (response.ok) {
      const { user, token } = responseData;
      user && setUser(user);
      token && setToken(token);
      yield put(userActions.loginSuccess(user));
    } else {
      removeUser();
      removeToken();
      yield put(userActions.authFailure(responseData));
    }
  } catch (error) {
    removeUser();
    removeToken();
    yield put(userActions.authFailure(error));
  }
}

export function* fetchUserProfile() {
  try {
    const url = `${process.env.REACT_APP_API_PROXY}/api/profile/me`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: setAuthHeaders()
    });
    const responseData = yield response.json();

    const { _id, createdAt, updatedAt, __v, ...rest } = responseData;
    yield put(userActions.fetchUserProfileSuccess({ ...rest }));
  } catch (error) {
    console.log(error);
  }
}

export function* onLoginStart() {
  yield takeLatest(userTypes.LOGIN_START, login);
}

export function* onFacebookLoginStart() {
  yield takeLatest(userTypes.FACEBOOK_LOGIN_START, facebookLogin);
}

export function* onGoogleLoginStart() {
  yield takeLatest(userTypes.GOOGLE_LOGIN_START, googleLogin);
}

export function* onLogoutStart() {
  yield takeLatest(userTypes.LOGOUT_START, logout);
}

export function* onRegisterStart() {
  yield takeLatest(userTypes.REGISTER_START, register);
}

export function* onCheckUserSession() {
  yield takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onFetchUserProfileStart() {
  yield takeLatest(userTypes.FETCH_PROFILE_START, fetchUserProfile);
}

export function* userSagas() {
  yield all([
    call(onLoginStart),
    call(onFacebookLoginStart),
    call(onGoogleLoginStart),
    call(onLogoutStart),
    call(onRegisterStart),
    call(onCheckUserSession)
  ]);
}
