import React from 'react';
import useStyles from './styles';
import { Link } from 'react-router-dom';

const Header = ({ fullScreen }) => {
  const classes = useStyles({ fullScreen });
  return (
    <header className={classes.header}>
      <div className={classes.logoContainer}>
        <Link to="/">
          <img className={classes.logo} src="/images/logo.png" alt="Logo" />
        </Link>
      </div>
    </header>
  );
};

export default Header;
