/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { getAthletes } from 'store/athlete/athlete_selectors';
import { fetchAthletesProfileStart } from 'store/athlete/athlete_actions';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Checkbox,
  Toolbar,
  Typography
} from '@material-ui/core';
import useStyles from './styles';

const Athletes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const athletes = useSelector(getAthletes);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [selected, setSelected] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = athletes.map((a) => a._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (!athletes.length) dispatch(fetchAthletesProfileStart());
  }, []);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Paper className={classes.paper}>
      <Toolbar
        className={clsx(classes.toolbar, {
          [classes.highlight]: selected.length > 0
        })}
      >
        {selected.length > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {selected.length} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Athletes
          </Typography>
        )}
      </Toolbar>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={selected.length > 0 && selected.length < athletes.length}
                checked={athletes.length > 0 && selected.length === athletes.length}
                onChange={handleSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </TableCell>
            <TableCell>athleteId</TableCell>
            <TableCell>firstname</TableCell>
            <TableCell>lastname</TableCell>
            <TableCell>ageCategory</TableCell>
            <TableCell>verify</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {athletes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={row._id} hover onClick={() => handleClick(row._id)}>
              <TableCell padding="checkbox">
                <Checkbox color="primary" checked={isSelected(row._id)} />
              </TableCell>
              <TableCell component="th" scope="row">
                {row.athleteId}
              </TableCell>
              <TableCell>{row.firstname}</TableCell>
              <TableCell>{row.lastname}</TableCell>
              <TableCell>{row.ageCategory}</TableCell>
              <TableCell>{row.verify ? 'YES' : 'NO'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={athletes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default Athletes;
