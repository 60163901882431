import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchEventsStart, deleteEventStart } from 'store/event/event_actions';
import { getEvents } from 'store/event/event_selectors';
import { fetchRacesStart } from 'store/race/race_actions';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import EventCard from 'pages/admin/Events/components/EventCard/EventCard';
import AddEvent from './components/AddEvent/AddEvent';
import useStyles from './styles';

const structuredSelector = createStructuredSelector({
  events: getEvents
});

const Events = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { events } = useSelector(structuredSelector);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [editedEvent, setEditedEvent] = useState(null);

  useEffect(() => {
    dispatch(fetchEventsStart());
    dispatch(fetchRacesStart());
  }, [dispatch]);

  const onAddEvent = () => {
    setShowAddEvent(!showAddEvent);
    setEditedEvent(null);
  };

  const onEditEvent = (id) => {
    const selectedEvent = events && events.find((event) => event._id === id);
    setEditedEvent(selectedEvent);
    setShowAddEvent(true);
  };

  const onDeleteEvent = (id) => dispatch(deleteEventStart(id));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.flex}>
              <Typography className={classes.title}>Events</Typography>
              <span className={classes.seperator}></span>
              {events && <Typography className={classes.details}>{events.length} Total</Typography>}
            </div>
            <Button className={classes.button} onClick={onAddEvent}>
              {showAddEvent ? 'Close Event Form' : 'Add Event'}
            </Button>
          </div>
        </Paper>
      </Grid>
      {showAddEvent && (
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <AddEvent editedEvent={editedEvent} />
          </Paper>
        </Grid>
      )}
      {events &&
        events.map((event) => (
          <Grid key={event._id} item xs={12} md={6} lg={3}>
            <EventCard event={event} onEditEvent={() => onEditEvent(event._id)} onDeleteEvent={() => onDeleteEvent(event._id)} />
          </Grid>
        ))}
    </Grid>
  );
};

export default Events;
