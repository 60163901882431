import racesTypes from './race_types';

const INITIAL_STATE = {
  races: [],
  loading: false,
  error: null
};

const racesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case racesTypes.FETCH_RACES_SUCCESS:
      return {
        ...state,
        races: payload,
        loading: false,
        error: null
      };
    case racesTypes.DELETE_RACE_SUCCESS:
      return {
        ...state,
        races: state.races.filter((race) => race._id !== payload),
        loading: false,
        error: null
      };
    case racesTypes.ADD_RACE_RESULT_SUCCESS: {
      const currentRace = state.races.find((race) => race._id === payload.raceId);
      if (!currentRace) return state;
      currentRace.results.push(payload);
      const filteredRaces = state.races.filter((race) => race._id !== payload.raceId);
      return { ...state, races: [...filteredRaces, currentRace] };
    }
    case racesTypes.EDIT_RACE_RESULT_SUCCESS: {
      const currentRace = state.races.find((race) => race._id === payload.raceId);
      if (!currentRace) return state;
      const currentRaceResults = currentRace.results.filter((result) => result._id !== payload._id);
      currentRaceResults.push(payload);
      currentRace.results = currentRaceResults;
      const filteredRaces = state.races.filter((race) => race._id !== payload.raceId);
      return { ...state, races: [...filteredRaces, currentRace] };
    }
    case racesTypes.ADD_RACE_FAILURE:
    case racesTypes.DELETE_RACE_FAILURE:
    case racesTypes.FETCH_RACES_FAILURE:
    case racesTypes.EDIT_RACE_RESULT_FAILURE:
      return { ...INITIAL_STATE, error: payload };
    default:
      return state;
  }
};

export default racesReducer;
