import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '@material-ui/core';
import { fetchEventsStart } from 'store/event/event_actions';
import { getEventById } from 'store/event/event_selectors';
import RaceSection from '../components/RaceSection/RaceSection';
import { Loader } from 'components';

const EventPage = ({ match }) => {
  const { eventId } = match.params;
  const dispatch = useDispatch();
  const selectedEvent = useSelector(getEventById({ eventId }));

  useEffect(() => {
    dispatch(fetchEventsStart());
  }, [dispatch]);

  return selectedEvent ? (
    <Container maxWidth="xl">
      <RaceSection races={selectedEvent.races} description={selectedEvent.description} />
    </Container>
  ) : (
    <Loader />
  );
};

EventPage.propTypes = {
  match: PropTypes.object
};

export default EventPage;
