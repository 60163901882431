/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserClaim } from 'store/claim/claim_selectors';
import { fetchClaimsStart } from 'store/claim/claim_actions';
import { fetchAthleteProfileStart } from 'store/athlete/athlete_actions';
import useStyles from './styles';

const Claims = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const claim = useSelector(getCurrentUserClaim);

  useEffect(() => {
    dispatch(fetchClaimsStart());
  }, []);

  useEffect(() => {
    if (claim) {
      dispatch(fetchAthleteProfileStart(claim.athleteId));
    }
  }, [claim]);

  return (
    <Paper className={classes.paper}>
      {/* {claim ? <pre>{JSON.stringify(claim, null, 3)}</pre> : 'You dont have a claim'} */}
      {claim ? (
        <Fragment>
          <Typography variant="h4">You have a {claim.status} claim</Typography>
          <Typography>Your Athelete id is : {claim.athleteId}</Typography>
        </Fragment>
      ) : (
        <Typography variant="h4">You dont have a claim</Typography>
      )}
    </Paper>
  );
};

Claims.propTypes = {};

export default Claims;
