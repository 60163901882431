import React from 'react';
import classnames from 'classnames';
import useStyles from './styles';

const ImageComposition = () => {
  const classes = useStyles();

  return (
    <div className={classes.composition}>
      <img
        sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
        alt="first"
        src="/images/3.jpg"
        className={classnames(classes.photo, classes.photo1)}
      />

      <img
        sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
        alt="second"
        className={classnames(classes.photo, classes.photo2)}
        src="/images/16.jpg"
      />

      <img
        sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
        alt="third"
        className={classnames(classes.photo, classes.photo3)}
        src="/images/24.jpg"
      />
    </div>
  );
};

export default ImageComposition;
