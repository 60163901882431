import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  root: {
    width: '50%',
    position: 'fixed',
    top: 24,
    left: '50%',
    right: 'auto',
    transform: 'translateX(-50%)',
    zIndex: 9999,
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));
