import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TopBar, Sidebar, Footer } from './components';
import useStyles from './styles';

function MainLayout({ children }) {
  const classes = useStyles();

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleToggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  return (
    <div className={classes.root}>
      <TopBar openSidebar={openSidebar} onToggleSidebar={handleToggleSidebar} />
      <Sidebar onClose={handleToggleSidebar} open={openSidebar} variant="temporary" />
      <main
        className={clsx({ [classes.contentShift]: openSidebar && false, [classes.content]: true })}
      >
        {children}
        <Footer />
      </main>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
