import React from 'react';
import ProfileIcon from '@material-ui/icons/Person';
import RunIcon from '@material-ui/icons/DirectionsRun';
import GroupIcon from '@material-ui/icons/Group';
import VerifyIcon from '@material-ui/icons/VerifiedUser';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

const pages = [
  {
    title: 'Athletes',
    href: '/admin/athletes',
    icon: <DirectionsRunIcon />
  },
  {
    title: 'Claims',
    href: '/admin/claims',
    icon: <VerifyIcon />
  },
  {
    title: 'Events',
    href: '/admin/events',
    icon: <EmojiEventsIcon />
  },
  {
    title: 'Races',
    href: '/admin/races',
    icon: <RunIcon />
  },
  {
    title: 'Teams',
    href: '/admin/teams',
    icon: <GroupIcon />
  },

  {
    title: 'Profile',
    href: '/admin/profile',
    icon: <ProfileIcon />
  }
];

export default pages;
