import eventsTypes from './event_types';

const INITIAL_STATE = {
  events: [],
  loading: false,
  error: null
};

const eventsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case eventsTypes.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: payload,
        loading: false,
        error: null
      };

    case eventsTypes.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter((event) => event._id !== payload),
        loading: false,
        error: null
      };
    case eventsTypes.FETCH_EVENTS_FAILURE:
    case eventsTypes.ADD_EVENT_FAILURE:
    case eventsTypes.DELETE_EVENT_FAILURE:
      return { ...INITIAL_STATE, error: payload };
    default:
      return state;
  }
};

export default eventsReducer;
