import userTypes from './user_types';

const INITIAL_STATE = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case userTypes.LOGIN_SUCCESS:
    case userTypes.REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
        loading: false,
        error: null
      };
    case userTypes.LOGOUT_SUCCESS:
      return INITIAL_STATE;
    case userTypes.AUTH_FAILURE:
      return { ...INITIAL_STATE, error: payload };
    default:
      return state;
  }
};

export default userReducer;
