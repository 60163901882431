import React from 'react';
import { Grid } from '@material-ui/core';
import ProfileDetails from 'components/ProfileDetails/ProfileDetails';
import Claims from './components/Claims';

const UserProfile = () => {
  return (
    <Grid container spacing={3} style={{ marginBottom: 30 }}>
      <Grid item lg={4} md={6} xl={4} xs={12}>
        <Claims />
      </Grid>
      <Grid item lg={8} md={6} xl={8} xs={12}>
        <ProfileDetails />
      </Grid>
    </Grid>
  );
};

export default UserProfile;
