import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Grid, Button, Typography, MenuItem } from '@material-ui/core';
import { getToken } from 'utils';
import { setAlertStart } from 'store/alert/alert_actions';
import { fetchAthletesProfileStart } from 'store/athlete/athlete_actions';
import useStyles from './styles';

const AddAthlete = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    athleteId: '',
    firstname: '',
    lastname: '',
    ageCategory: 'N/A'
  });

  const { athleteId, firstname, lastname, ageCategory } = form;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  // TODO: move to sagas
  const handleSave = async () => {
    try {
      const token = getToken();
      const url = `${process.env.REACT_APP_API_PROXY}/api/profile`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(form)
      });
      const responseData = await response.json();
      if (response.ok) {
        dispatch(setAlertStart('Athlete Added', 'success', 5000));
        dispatch(fetchAthletesProfileStart());
      } else {
        dispatch(setAlertStart(responseData.message, 'error', 5000));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid className={classes.root} container spacing={3} alignItems="flex-end">
      <Grid item xs={12}>
        <Typography className={classes.modeTitle} variant="h2">
          Add New Athlete
        </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth label="Athelete Id" name="athleteId" value={athleteId} onChange={handleChange} />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField fullWidth label="First Name" name="firstname" value={firstname} onChange={handleChange} required />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth label="Last Name" name="lastname" value={lastname} onChange={handleChange} required />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField fullWidth select label="Age Category" name="ageCategory" value={ageCategory} onChange={handleChange} required>
          {['N/A', 'M', 'M30', 'M40', 'M50', 'M60', 'F', 'F30', 'F40', 'F50', 'F60'].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} md={3}>
        <Button fullWidth className={classes.button} variant="contained" color="primary" onClick={handleSave}>
          Add Athlete
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddAthlete;
