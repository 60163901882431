import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  card: {
    width: '100%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  icons: {
    marginRight: theme.spacing(2)
  },
  actions: {
    padding: theme.spacing(1, 3),
    justifyContent: 'space-between'
  }
}));
