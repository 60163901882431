import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import useStyles from './styles';

const Entry = ({ title, head, data }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.entryTitle} variant="h5">
        {title}
      </Typography>
      <Table style={{ width: '100%', minWidth: 350 }}>
        <TableHead>
          <TableRow>
            {head.map((item) => (
              <TableCell className={classes.head} key={item.id}>
                {item.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {head.map((item) => (
              <TableCell style={{ fontSize: 10 }} key={item.id}>
                {data[item.id]}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

Entry.propTypes = { title: PropTypes.string, head: PropTypes.array, data: PropTypes.object };

export default Entry;
