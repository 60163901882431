import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCurrentUser } from 'store/user/user_selectors';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { List, ListItem, ListItemIcon, ListItemText, Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import SettingsIcon from '@material-ui/icons/Settings';
import LeftIcon from '@material-ui/icons/ArrowForwardIos';
import ProfileIcon from '@material-ui/icons/Person';
import BadgeAvatars from 'components/BadgeAvatars/BadgeAvatars';
import ThemeToggler from 'components/ThemeToggler/ThemeToggler';

const useStyles = makeStyles((theme) => ({
  badge: {
    cursor: 'pointer'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 300,
    padding: theme.spacing(3),
    background: theme.palette.custom.primary,
    color: theme.palette.custom.contrastText
  },
  content: {
    padding: theme.spacing(1)
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3)
  },
  button: {
    padding: theme.spacing(1, 4)
  }
}));

const structuredSelector = createStructuredSelector({
  currentUser: getCurrentUser
});

export default function UserMenu({ handleLogout }) {
  const classes = useStyles();
  const { currentUser } = useSelector(structuredSelector);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div className={classes.badge} onClick={handleClick}>
        <BadgeAvatars />
      </div>
      <Popover
        className={classes.popover}
        id="user-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={classes.header}> {`Signed in as ${currentUser.username}`}</div>
        <div className={classes.content}>
          <List component="nav">
            <ListItem button component={NavLink} to={'/admin/profile'}>
              <ListItemIcon>
                <ProfileIcon />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
              <LeftIcon fontSize="small" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
              <LeftIcon fontSize="small" />
            </ListItem>
            <ThemeToggler />
          </List>
          <Divider />
        </div>
        <div className={classes.footer}>
          <Button fullWidth className={classes.button} onClick={handleLogout}>
            Log Out
          </Button>
        </div>
      </Popover>
    </div>
  );
}
