import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAuthenticatedUser } from 'store/user/user_selectors';
import { loginStart, googleLoginStart, facebookLoginStart, authFailure } from 'store/user/user_actions';
import { Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import useStyles from './styles';

const structuredSelector = createStructuredSelector({
  isAuthenticated: getAuthenticatedUser
});

const Login = ({ history, location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(structuredSelector);
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: ''
  });

  const { email, password } = userCredentials;

  const { from } = location.state || { from: { pathname: '/admin/profile' } };
  useEffect(() => {
    if (isAuthenticated) history.replace(from);
  }, [isAuthenticated, history, from]);

  const handleLogin = () => {
    dispatch(loginStart({ email, password }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  const handleBack = () => {
    history.goBack();
  };

  const googleLogin = (event) => {
    const { error, profileObj } = event;
    if (error) {
      return dispatch(authFailure(error));
    }
    return dispatch(googleLoginStart(profileObj));
  };
  const facebookLogin = (event) => {
    dispatch(facebookLoginStart(event));
  };

  const isValid = email && password;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.bgWrapper} item lg={4}>
          <div className={classes.bg} />
        </Grid>
        <Grid className={classes.content} item lg={8} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton className={classes.backButton} onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form}>
                <Typography className={classes.title} variant="h2">
                  Login
                </Typography>
                <Typography className={classes.subtitle} variant="body1">
                  Login using your email and password.
                </Typography>

                <div className={classes.socialLogin}>
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    onSuccess={googleLogin}
                    onFailure={googleLogin}
                    cookiePolicy={'single_host_origin'}
                    render={(renderProps) => (
                      <Button
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        fullWidth
                        variant="contained"
                        style={{
                          borderRadius: 0,
                          background: '#fff',
                          color: '#de5246',
                          marginBottom: 10,
                          height: 60,
                          fontSize: 'calc(.27548vw + 12.71074px)',
                          fontWeight: 700
                        }}
                      >
                        Login With Google
                      </Button>
                    )}
                  />
                  <FacebookLogin
                    buttonStyle={{ width: '100%', height: 60 }}
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID} //APP ID NOT CREATED YET
                    fields="name,email,picture"
                    callback={facebookLogin}
                  />
                </div>
                <div className={classes.fields}>
                  <TextField
                    className={classes.textField}
                    label="Email address"
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    variant="outlined"
                    autoComplete="off"
                  />

                  <TextField
                    className={classes.textField}
                    label="Password"
                    type="password"
                    name="password"
                    value={password}
                    variant="outlined"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </div>

                <Button
                  className={classes.registerButton}
                  color="primary"
                  disabled={!isValid}
                  onClick={handleLogin}
                  size="large"
                  variant="contained"
                >
                  Login
                </Button>

                <Typography className={classes.register} variant="body1">
                  Do not have an account?{' '}
                  <Link className={classes.registerUrl} to="/register">
                    Register
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default Login;
