import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ColoredHeading from 'components/ColoredHeading/ColoredHeading';
import { Container, Grid, Typography } from '@material-ui/core';
import { fetchEventsStart } from 'store/event/event_actions';
import { getPublishedEvents } from 'store/event/event_selectors';
import EventSimpleCard from '../EventSimpleCard/EventSimpleCard';
import useStyles from './styles';

const EventSection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const publishedEvents = useSelector(getPublishedEvents);

  useEffect(() => {
    dispatch(fetchEventsStart());
  }, [dispatch]);

  const sortedEvents = publishedEvents.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

  return (
    <div className={classes.section}>
      <ColoredHeading>Αποτελεσματα Αγωνων</ColoredHeading>
      <Container>
        <Typography className={classes.headingTertiary} variant="h3" align="center">
          Λιστα Αγωνων
        </Typography>
        {sortedEvents.length ? (
          <Grid container spacing={5}>
            {sortedEvents.map((event) => (
              <Grid key={event._id} item xs={12} md={sortedEvents.length > 1 ? 6 : 12}>
                <EventSimpleCard event={event} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography align="center">Δεν υπάρχουν διαθέσιμα Events... </Typography>
        )}
      </Container>
    </div>
  );
};

export default EventSection;
