import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { addRaceStart, editRaceStart } from 'store/race/race_actions';
import { Grid, Button, Typography, TextField } from '@material-ui/core';
import useStyles from './styles';

const INITIAL_FORM = {
  name: '',
  type: 'running',
  description: '',
  distance: '',
  startDate: '',
  endDate: '',
  city: '',
  state: '',
  country: ''
};

const AddRace = ({ editedRace }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState(INITIAL_FORM);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    editedRace ? dispatch(editRaceStart(form)) : dispatch(addRaceStart(form));
  };

  useEffect(() => {
    if (editedRace) {
      setForm({ ...editedRace });
    }
  }, [editedRace]);

  return (
    <Grid className={classes.root} container spacing={3} alignItems="flex-end">
      <Grid item xs={12}>
        <Typography className={classes.modeTitle} variant="h2">
          {editedRace ? 'Edit ' : 'Add New '} Race
        </Typography>
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          label="Race Name"
          name="name"
          value={form.name}
          variant="outlined"
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          label="Event Type"
          name="type"
          value={form.type}
          variant="outlined"
          onChange={handleChange}
          required
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          type="number"
          label="Distance"
          name="distance"
          value={form.distance}
          variant="outlined"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true
          }}
          required
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          type="date"
          label="Start Date"
          name="startDate"
          value={form.startDate}
          variant="outlined"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true
          }}
          required
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          fullWidth
          type="date"
          label="End Date"
          name="endDate"
          value={form.endDate}
          variant="outlined"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true
          }}
          required
        />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField fullWidth label="City" name="city" value={form.city} variant="outlined" onChange={handleChange} />
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField fullWidth label="State" name="state" value={form.state} variant="outlined" onChange={handleChange} />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField fullWidth label="Country" name="country" value={form.country} variant="outlined" onChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Description"
          name="description"
          multiline
          rows={4}
          value={form.description}
          variant="outlined"
          onChange={handleChange}
          required
        />
      </Grid>

      <Grid item xs={12} container>
        <Grid item xs={12} md={3}>
          <Button fullWidth className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
            {editedRace ? 'Edit ' : 'Add New '} Race
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddRace.propTypes = { editedRace: PropTypes.object };

export default AddRace;
