import userTypes from './user_types';

export const loginStart = (emailAndPassword) => ({
  type: userTypes.LOGIN_START,
  payload: emailAndPassword
});

export const facebookLoginStart = (event) => ({
  type: userTypes.FACEBOOK_LOGIN_START,
  payload: event
});

export const googleLoginStart = (profileObj) => ({
  type: userTypes.GOOGLE_LOGIN_START,
  payload: profileObj
});

export const loginSuccess = (user) => ({
  type: userTypes.LOGIN_SUCCESS,
  payload: user
});

export const logoutStart = () => ({
  type: userTypes.LOGOUT_START
});

export const logoutSuccess = () => ({
  type: userTypes.LOGOUT_SUCCESS
});

export const registerStart = (userCredentials) => ({
  type: userTypes.REGISTER_START,
  payload: userCredentials
});

export const registerSuccess = (user) => ({
  type: userTypes.REGISTER_SUCCESS,
  payload: user
});

export const checkUserSession = () => ({
  type: userTypes.CHECK_USER_SESSION
});

export const authFailure = (error) => ({
  type: userTypes.AUTH_FAILURE,
  payload: error
});

export const fetchUserProfileStart = () => ({
  type: userTypes.FETCH_PROFILE_START
});

export const fetchUserProfileSuccess = (profile) => ({
  type: userTypes.FETCH_PROFILE_SUCCESS,
  payload: profile
});

export const fetchUserProfileFailure = (error) => ({
  type: userTypes.FETCH_PROFILE_FAILURE,
  payload: error
});
