import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAuthenticatedUser, getCurrentUser } from 'store/user/user_selectors';

const structuredSelector = createStructuredSelector({
  isAuthenticated: getAuthenticatedUser,
  user: getCurrentUser
});

const ProtectedRoute = ({ layout: Layout, component: Component, ...rest }) => {
  const { isAuthenticated, user } = useSelector(structuredSelector);
  if (isAuthenticated && user.role !== 'superadmin') {
    // redirect to home page if not superadmin
    return <Redirect to={{ pathname: '/' }} />;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  layoutProps: PropTypes.object
};

export default ProtectedRoute;
