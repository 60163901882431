import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import ThemeProvider from './theme';
import Routes from './routes/Routes';
import ThemeContext from 'theme/ThemeContext';
import { useDispatch } from 'react-redux';
import { checkUserSession } from 'store/user/user_actions';
import { getStorage } from 'utils/localStorage';
import Alert from 'components/Alert/Alert';
import history from './history';
import { isAlive } from 'utils/isAlive';

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
ReactGA.initialize(trackingId);
// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  const cachedTheme = getStorage('theme') || 'materialDark';
  const [theme, setTheme] = useState(() => cachedTheme);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());
    isAlive();
  }, [dispatch]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider>
        <Alert />
        <Router history={history}>
          <Routes />
        </Router>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
