const userTypes = {
  LOGIN_START: 'LOGIN_START',
  FACEBOOK_LOGIN_START: 'FACEBOOK_LOGIN_START',
  GOOGLE_LOGIN_START: 'GOOGLE_LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_START: 'LOGOUT_START',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  REGISTER_START: 'REGISTER_START',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  AUTH_FAILURE: 'AUTH_FAILURE',

  FETCH_PROFILE_START: 'FETCH_PROFILE_START',
  FETCH_PROFILE_SUCCESS: 'FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_FAILURE: 'FETCH_PROFILE_FAILURE'
};

export default userTypes;
