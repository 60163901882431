import athleteTypes from './athlete_types';

export const createAthleteProfileStart = (profile) => ({
  type: athleteTypes.CREATE_ATHLETE_PROFILE_START,
  payload: profile
});

export const createAthletesProfileSuccess = (profile) => ({
  type: athleteTypes.CREATE_ATHLETE_PROFILE_SUCCESS,
  payload: profile
});

export const updateAthleteProfileStart = (profile) => ({
  type: athleteTypes.UPDATE_ATHLETE_PROFILE_START,
  payload: profile
});

export const updateAthletesProfileSuccess = (profile) => ({
  type: athleteTypes.UPDATE_ATHLETE_PROFILE_SUCCESS,
  payload: profile
});

export const fetchAthletesProfileStart = () => ({
  type: athleteTypes.FETCH_ATHLETES_PROFILE_START
});

export const fetchAthletesProfileSuccess = (athletes) => ({
  type: athleteTypes.FETCH_ATHLETES_PROFILE_SUCCESS,
  payload: athletes
});

export const fetchAthleteProfileStart = (athleteId) => ({
  type: athleteTypes.FETCH_ATHLETE_PROFILE_START,
  payload: athleteId
});

export const fetchMyAthleteProfileStart = () => ({
  type: athleteTypes.FETCH_MY_ATHLETE_PROFILE_START
});

export const fetchAthleteProfileSuccess = (athlete) => ({
  type: athleteTypes.FETCH_ATHLETE_PROFILE_SUCCESS,
  payload: athlete
});

export const athletesProfileFailure = (error) => ({
  type: athleteTypes.ATHLETES_PROFILE_FAILURE,
  payload: error
});
