const addHeaders = (httpHeaders) => new Headers(httpHeaders);

const headers = addHeaders({
  'Content-Type': 'application/json'
});

const get = (url) => {
  const requestOptions = { method: 'GET', headers };
  return fetch(url, requestOptions);
};

const post = (url, data) => {
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  };

  return fetch(url, requestOptions);
};

export const isAlive = () => {
  const url = process.env.REACT_APP_API_PROXY;
  const requestOptions = { method: 'GET', headers };
  return fetch(url, requestOptions);
};

export default {
  get,
  post,
  isAlive
};
