import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import GroupIcon from '@material-ui/icons/GroupAddOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import useStyles from './styles';

export default function RaceResultsTableToolbar(props) {
  const classes = useStyles();
  const {
    numSelected,
    onShowAddAthlete,
    onShowAddResult,
    onEditSelected,
    onDeleteSelected,
    onShowAddGroup
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Results
        </Typography>
      )}

      {numSelected === 1 && (
        <Tooltip title="Edit">
          <IconButton aria-label="edit" onClick={onEditSelected}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={onDeleteSelected}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Add New Group">
        <IconButton onClick={onShowAddGroup} aria-label="Add New Group">
          <GroupIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add New Athlete">
        <IconButton onClick={onShowAddAthlete} aria-label="Add New Athlete">
          <PersonAddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Toggle Add Race Result">
        <IconButton onClick={onShowAddResult} aria-label="Toggle add race result">
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

RaceResultsTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onShowAddAthlete: PropTypes.func,
  onShowAddResult: PropTypes.func,
  onDeleteSelected: PropTypes.func,
  onEditSelected: PropTypes.func
};
