import racesTypes from './race_types';

export const fetchRacesStart = () => ({
  type: racesTypes.FETCH_RACES_START
});

export const fetchRacesSuccess = (races) => ({
  type: racesTypes.FETCH_RACES_SUCCESS,
  payload: races
});

export const fetchRacesFailure = (error) => ({
  type: racesTypes.FETCH_RACES_FAILURE,
  payload: error
});

export const addRaceStart = (race) => ({
  type: racesTypes.ADD_RACE_START,
  payload: race
});

export const addRaceSuccess = (races) => ({
  type: racesTypes.ADD_RACE_SUCCESS,
  payload: races
});

export const addRaceFailure = (error) => ({
  type: racesTypes.ADD_RACE_FAILURE,
  payload: error
});

export const editRaceStart = (race) => ({
  type: racesTypes.EDIT_RACE_START,
  payload: race
});

export const editRaceSuccess = (races) => ({
  type: racesTypes.EDIT_RACE_SUCCESS,
  payload: races
});

export const editRaceFailure = (error) => ({
  type: racesTypes.EDIT_RACE_FAILURE,
  payload: error
});

export const deleteRaceStart = (raceId) => ({
  type: racesTypes.DELETE_RACE_START,
  payload: raceId
});

export const deleteRaceSuccess = (races) => ({
  type: racesTypes.DELETE_RACE_SUCCESS,
  payload: races
});

export const deleteRaceFailure = (error) => ({
  type: racesTypes.DELETE_RACE_FAILURE,
  payload: error
});

export const registerUserOnRaceStart = (raceId, userId) => ({
  type: racesTypes.REGISTER_USER_ON_RACE_START,
  payload: { raceId, userId }
});

export const registerUserOnSuccess = (message) => ({
  type: racesTypes.REGISTER_USER_ON_RACE_SUCCESS,
  payload: message
});

export const registerUserOnFailure = (error) => ({
  type: racesTypes.REGISTER_USER_ON_RACE_FAILURE,
  payload: error
});

export const addRaceResultStart = (result) => ({
  type: racesTypes.ADD_RACE_RESULT_START,
  payload: result
});

export const addRaceResultSuccess = (result) => ({
  type: racesTypes.ADD_RACE_RESULT_SUCCESS,
  payload: result
});

export const addRaceResultFailure = (error) => ({
  type: racesTypes.ADD_RACE_RESULT_FAILURE,
  payload: error
});

export const editRaceResultStart = (result, resultId) => ({
  type: racesTypes.EDIT_RACE_RESULT_START,
  payload: { result, resultId }
});

export const editRaceResultSuccess = (result) => ({
  type: racesTypes.EDIT_RACE_RESULT_SUCCESS,
  payload: result
});

export const editRaceResultFailure = (error) => ({
  type: racesTypes.EDIT_RACE_RESULT_FAILURE,
  payload: error
});

export const deleteRaceResultStart = (resultIds) => ({
  type: racesTypes.DELETE_RACE_RESULTS_START,
  payload: resultIds
});

export const deleteRaceResultSuccess = (result) => ({
  type: racesTypes.DELETE_RACE_RESULTS_SUCCESS,
  payload: result
});

export const deleteRaceResultFailure = (error) => ({
  type: racesTypes.DELETE_RACE_RESULTS_FAILURE,
  payload: error
});

export const fetchRaceResultsStart = (raceId) => ({
  type: racesTypes.FETCH_RACE_RESULTS_BY_ID_START,
  payload: raceId
});

export const fetchRaceResultsSuccess = (results) => ({
  type: racesTypes.FETCH_RACE_RESULTS_BY_ID_SUCCESS,
  payload: results
});

export const fetchRaceResultsFailure = (error) => ({
  type: racesTypes.FETCH_RACE_RESULTS_BY_ID_FAILURE,
  payload: error
});
