import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const WithLayoutRoute = (props) => {
  const { layout: Layout, component: Component, layoutProps, location, ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout {...layoutProps}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

WithLayoutRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  location: PropTypes.object,
  layoutProps: PropTypes.object
};

export default WithLayoutRoute;
