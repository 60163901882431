import React from 'react';
import { Paper, Button } from '@material-ui/core';
import SimpleProfile from 'components/SimpleProfile/SimpleProfile';
import useStyles from './styles';

const ProfileAccount = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <SimpleProfile />
      <Button className={classes.button} fullWidth>
        Remove Picture
      </Button>
    </Paper>
  );
};

export default ProfileAccount;
