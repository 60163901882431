import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  head: {
    background: 'rgb(80, 128, 60)',
    color: theme.palette.common.white,
    fontSize: 11
  },
  entryTitle: { marginBottom: theme.spacing(2), fontSize: 15 },
  button: {
    color: '#fff',
    padding: theme.spacing(2, 5),
    textTransform: 'capitalize',
    backgroundColor: 'rgb(80, 128, 60)',
    '&:hover': {
      backgroundColor: 'rgb(255, 152, 52)'
    }
  }
}));
