/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRaceResultsStart } from 'store/race/race_actions';
import { calculateRanking } from 'utils/pacecalculator';
import RaceResultsTable from './components/RaceResultsTable/RaceResultsTable';

const RaceResults = ({ match, history }) => {
  const { raceId } = match.params;
  const dispatch = useDispatch();
  const { races } = useSelector((state) => state.race);
  const selectedRace = races && races.find((race) => race._id === raceId);

  useEffect(() => {
    if (!races) history.replace({ pathname: '/admin/races' });
    dispatch(fetchRaceResultsStart(raceId));
  }, [races]);

  return selectedRace && <RaceResultsTable results={calculateRanking(selectedRace.results)} raceId={raceId} />;
};

RaceResults.propTypes = { match: PropTypes.object, history: PropTypes.object };

export default RaceResults;
