import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MUIAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import useStyles from './styles';

const Alert = () => {
  const classes = useStyles();
  const { alerts } = useSelector((state) => state.alert);

  return (
    <div className={classes.root}>
      {alerts.length > 0 &&
        alerts.map((alert, index) => (
          <MUIAlert key={`custom-alert-${index}-${alert.id}`} severity={alert.alertType}>
            <AlertTitle>{alert.alertType}</AlertTitle>
            {alert.msg}
          </MUIAlert>
        ))}
    </div>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

Alert.defaultProps = {
  alerts: []
};

export default Alert;
