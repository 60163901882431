import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import useStyles from './styles';

const headCells = [
  { id: 'generalRanking', label: 'Γενική Κατάταξη' },
  { id: 'sexRanking', label: 'Φύλλο' },
  { id: 'ageRanking', label: 'Ηλικιακή Κατηγορία' },
  { id: 'points', label: 'Πόντοι' },
  { id: 'fullname', label: 'Όνομα / Επώνυμο' },
  { id: 'ageCategory', label: 'Ηλικιακή Κατηγορία' },
  { id: 'group', label: 'Ομάδα' },
  { id: 'distance', label: 'Απόσταση' },
  { id: 'totalTime', label: 'Συνολικός Χρόνος' },
  { id: 'tempo', label: 'Μέσος Χρόνος' },
  { id: 'elevation', label: 'Υψόμετρική Διαφορά' }
];

const RaceResultsTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell className={classes.tableCell} key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

RaceResultsTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default RaceResultsTableHead;
