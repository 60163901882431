import { combineReducers } from 'redux';
import alertReducer from './alert/alert_reducer';
import userReducer from './user/user_reducer';
import raceReducer from './race/race_reducer';
import athleteReducer from './athlete/athlete_reducer';
import eventsReducer from './event/event_reducer';
import teamReducer from './team/team_reducer';
import claimReducer from './claim/claim_reducer';

const rootReducer = combineReducers({
  alert: alertReducer,
  user: userReducer,
  race: raceReducer,
  athlete: athleteReducer,
  event: eventsReducer,
  team: teamReducer,
  claim: claimReducer
});

export default rootReducer;
