import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import useStyles from './styles';

const headCells = [
  { id: 'athleteId', align: 'left', padding: 'default', label: 'Athlete Id' },
  { id: 'firstname', align: 'left', padding: 'default', label: 'First Name' },
  { id: 'lastname', align: 'left', padding: 'default', label: 'Last Name' },
  { id: 'ageCategory', align: 'right', padding: 'default', label: 'Age Category' },
  { id: 'group', align: 'right', padding: 'default', label: 'Group' },
  { id: 'distance', align: 'right', padding: 'default', label: 'Distance' },
  { id: 'totalTime', align: 'right', padding: 'default', label: 'Total Time' },
  { id: 'tempo', align: 'right', padding: 'default', label: 'Tempo (min/km)' },
  { id: 'elevation', align: 'right', padding: 'default', label: 'Elevation' },
  { id: 'generalRanking', align: 'left', padding: 'default', label: 'General Ranking' },
  { id: 'sexRanking', align: 'right', padding: 'default', label: 'Sex Ranking' },
  { id: 'ageRanking', align: 'right', padding: 'default', label: 'Age Ranking' },
  { id: 'points', align: 'right', padding: 'default', label: 'Points' }
];

const RaceResultsTableHead = (props) => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all race results' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

RaceResultsTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default RaceResultsTableHead;
