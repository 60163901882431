import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.background.default}`,
    boxShadow: `0 0 35px 0  ${theme.palette.background.default}`,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    height: 80,
    zIndex: theme.appBar
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
    paddingLeft: 0
  },
  brandWrapper: {
    background: theme.palette.custom.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 271,
    height: 80,
    flexShrink: 0
  },
  logo: {
    width: '100%',
    maxWidth: '100%',
    margin: 'auto',
    padding: theme.spacing(1),
    fontFamily: 'Montserrat,sans-serif',
    textAlign: 'center',
    fontSize: 22,
    fontWeight: 700,
    letterSpacing: 3,
    color: theme.palette.custom.contrastText
  },
  logoImage: { width: '70%' },
  title: {
    marginLeft: theme.spacing(5),
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: 14,
    color: theme.palette.contrastText
  },
  menuButton: {
    color: theme.palette.custom.contrastText,
    marginLeft: -4
  },
  userSettings: {
    marginLeft: 'auto',
    marginRight: theme.spacing(3)
  },
  notificationsButton: {
    margin: theme.spacing(0, 1)
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  [theme.breakpoints.down('sm')]: {
    brandWrapper: { width: 75 },
    logo: { display: 'none' }
  }
}));
